<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName"
            @keyup.enter="autoSave = false">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="event_name"
                                   label="Event name"
                                   size="6"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.event_name" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.symbol" />
                    <FormRadioButton fieldName="percent_value"
                                     label="Percent value"
                                     size="3"
                                     :default="0"
                                     :data="percentValues"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.percent_value" />


                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>

            <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>

        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled"
                         v-model:autoSave="autoSave">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const autoSave = ref(false)

const crud = ref({})
const percentValues = [
    { label: '0%', value: 0 },
    { label: '100%', value: 100 },
]
const buttonsVisibility = ref(null);

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, null, null, buttonsVisibility)
})

// -----------------------------------------------------


</script>

<style lang="scss"
       scoped></style>
