<template>
    <VLayout class="rounded rounded-md">
        <VAppBar elevation="10"
                 density="compact"
                 order="10"
                 title=' '
                 class="p-1"
                 :style="toSlideStyle">
            <template #prepend>
                <v-col style="height:auto;">
                    <Image :src="menuLogo" />
                </v-col>
            </template>

            <VAppBarTitle class="flex w-full justify-content-center absolute">
                <span>{{ systemCaption }}</span>
            </VAppBarTitle>

            <template #append>
                <div style="margin-right:20px">
                    <Button v-if="multiModuleApp"
                            icon="pi pi-server"
                            severity="info"
                            text
                            size="large"
                            v-tooltip.top="__('Select module')"
                            @click="selectModule" />

                </div>
                <div class="session-time-header"
                     style="margin-right:20px"></div>
            </template>
        </VAppBar>
        <VNavigationDrawer elevation="20"
                           location="start"
                           order="0"
                           :expand-on-hover="expandableMenu"
                           rail
                           :rail-width="expandableMenu ? 60 : 200"
                           rounded
                           width="250"
                           class="menu-vertical-outer"
                           @update:rail="slideDrawer">
            <div v-if="itemVisibility"
                 class="flex flex-row align-items-center justify-content-between pr-2 w-full">
                <VListItem title="Nawigacja"
                           subtitle="menu" />
                <div v-if="expandableMenu"
                     class="fa-solid fa-left-long"
                     style="font-size: 25px;"></div>
            </div>
            <div v-else
                 class="fa-solid fa-right-long flex flex-row justify-content-center w-full"
                 style="font-size: 25px;"></div>
            <VDivider></VDivider>
            <div class="card flex flex-row justify-content-center">
                <PanelMenu :model="menuItems"
                           class="w-full"
                           :pt="{
                    menuItem: {
                        class: 'menu-vertical-before-inner'
                    }
                }">

                    <template #item="{ item, props, hasSubmenu, active }">
                        <span v-if="Object.values(unprotectedMenuItems).includes(item?.fullPath?.toLowerCase().replace(/[_\s]/g, '-')) || hasPermission(item?.fullPath)">
                            <Link v-ripple
                                  v-if="item.route"
                                  :href="checkRoute(item.route, null, item.label)"
                                  :id="item.id"
                                  :style="item.itemstyle"
                                  class="flex flex-row flex-nowrap menu-vertical-inner"
                                  style='text-decoration:none;'
                                  v-bind="props.action">
                            <div inert
                                 class='px-3 py-3 cursor-pointer w-100'>
                                <span :class="item.icon"
                                      :style="item.iconstyle" />
                                <span v-if="itemVisibility"
                                      class="ml-2"
                                      :class="item.labelclass"
                                      :style="item.labelstyle">{{ __(item.label) }}</span>
                                <div v-if="item.description && itemVisibility"
                                     class="px-4 py-0 menu-vertical-description">
                                    <span v-html="item.description"></span>
                                </div>
                            </div>
                            </Link>

                            <div v-ripple
                                 v-else
                                 class="flex flex-row flex-nowrap menu-vertical-inner"
                                 style='text-decoration:none;'
                                 :style="item.itemstyle"
                                 v-bind="props.action"
                                 @click="stateOfSubmenu(hasSubmenu, item.label, active)">
                                <div class="px-3 py-3 cursor-pointer w-100">
                                    <span :class="item.icon"
                                          :style="item.iconstyle" />
                                    <span v-if="itemVisibility"
                                          class="ml-2"
                                          :class="item.labelclass"
                                          :style="item.labelstyle">{{ __(item.label) }}</span>
                                    <i v-if="hasSubmenu && itemVisibility"
                                       :class="['pi pi-angle-down text-primary', { 'pi-angle-down ml-2': expandedSubmenu[item.label], 'pi-angle-right ml-2': !expandedSubmenu[item.label] }]"
                                       :style="item.submenustyle"
                                       style="float:right"></i>
                                    <div v-if="item.description && itemVisibility"
                                         class="px-4 py-0 menu-vertical-description"
                                         :id="item.id">
                                        <span v-html="item.description"></span>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </template>
                </PanelMenu>
            </div>
        </VNavigationDrawer>

        <VMain :style="toSlideStyle">
            <div class="layout-box">
                <slot></slot>
            </div>
        </VMain>

        <VFooter class="bg-grey-lighten-2"
                 app>
            <div class="w-full flex justify-content-between align-items-center">
                <div class="flex flex-nowrap align-items-center">
                    <Image :src="developerLogo"
                           style="width:100px;" />
                    <div style="width:200px !important;">
                        <span class="text-sm text-grey-darkest">Powered by © 4Evolve wersja 2.0</span>
                    </div>
                </div>
                <div class="flex justify-content-between align-self-center">
                    <span class="footer-custom-info">{{ premiumPeriod }}</span>
                    <span class="footer-custom-info"
                          style="padding-left:50px;color:rosybrown">{{ currentDate }}</span>
                    <span v-if="deputyName"
                          style="padding-left:50px"
                          class="deputy-title">{{ __('Replaced worker') }}:
                        <span class="deputy-name">{{ deputyName }}
                        </span>
                    </span>
                </div>
                <!-- <div class="flex w-full justify-content-center align-self-center">
                    <strong>Akwedukt 2.0</strong>
                </div> -->
                <div class="flex flex-row flex-nowrap align-items-center">
                    <div class="flex flex-nowrap justify-content-start align-items-center"
                         style="width:500px">
                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-help', null, null)">
                        <span class="mx-2 footer-links">Pomoc</span>
                        </Link>

                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-changelog', null, null)">
                        <span class="mx-2 footer-links">Changelog</span>
                        </Link>

                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-privacy', null, null)">
                        <span class="mx-2 footer-links">Polityka prywatności</span>
                        </Link>
                    </div>

                    <div id='user-name-area'
                         style="text-wrap:nowrap">
                        {{ userName }}
                    </div>
                    <div>
                        <Button icon="pi pi-save"
                                severity="info"
                                text
                                size="large"
                                v-tooltip.top="__('Save session')"
                                @click="saveSession" />
                    </div>
                </div>
            </div>
        </VFooter>

    </VLayout>
    <flash-message />
</template>

<script setup>
import { ref, onMounted, onBeforeMount, nextTick } from 'vue'
import { Link, router, usePage } from '@inertiajs/vue3'
import PanelMenu from 'primevue/panelmenu';
import Button from 'primevue/button';
import Image from '@helper/Image.vue'
import { initFunctions } from "../../init.js";
import { __, saveAppSession, getStoreValue, getSessionValue, hasPermission, checkRoute, setStoreValue, getServerValue } from '@pages/Helper/utils.js';
import { menuLogo, developerLogo } from '@helper/constants.js'
import axios from 'axios'

const menuItems = ref(null);
const unprotectedMenuItems = ref({});
const userName = ref(null);
const multiModuleApp = ref(false);

const toSlideStyle = ref("margin-left:0px;")
const itemVisibility = ref(true)
const submenuState = ref({})
const expandedSubmenu = ref({})
const systemCaption = ref(getStoreValue('appCaption'))
const expandableMenu = ref(false)

const premiumPeriod = ref(null);
const currentDate = ref(null)
const deputyName = ref(null);
const deputyInformation = ref(null);
const page = usePage();

//------------------------------------------------------------------------------

watchEffect(async () => {
    let savedPremiumPeriod = null
    savedPremiumPeriod = getStoreValue('premiumPeriod')

    if (!savedPremiumPeriod) {
        savedPremiumPeriod = await getServerValue('premiumPeriod')
        if (savedPremiumPeriod?.hasOwnProperty('premiumPeriod')) {
            setStoreValue('premiumPeriod', savedPremiumPeriod.premiumPeriod)
            savedPremiumPeriod = savedPremiumPeriod.premiumPeriod
        }
    }

    if (savedPremiumPeriod) {
        premiumPeriod.value = formatPremiumPeriod(savedPremiumPeriod)
    }
});

//------------------------------------------------------------------------------

watchEffect(async () => {
    let savedDeputyName = null
    savedDeputyName = getStoreValue('deputyName')
    if (!savedDeputyName) {
        const response = await getFromRoute("worker-deputy", {}, 'post');
        savedDeputyName = response.deputy_name
        if (savedDeputyName) {
            setStoreValue('savedDeputyName', savedDeputyName)
            deputyInformation.value = savedDeputyName
        } else {
            setStoreValue('savedDeputyName', null)
        }
    }

    if (savedDeputyName) {
        deputyName.value = savedDeputyName
    } else {
        deputyName.value = null
    }
});

//------------------------------------------------------------------------------

watchEffect(async () => {
    currentDate.value = page.props.currentDate;
});

// ------------------------------------------------------------------------------
onBeforeMount(async () => {
    const response = await axios.post(route("menu-content"));
    const backendData = await response.data;

    menuItems.value = backendData.menu;
    if (menuItems.value.length == 0) {
        console.error('Wybrany moduł (' + backendData.moduleSymbol + ') nie posiada pliku json z zawartością menu. Nastąpi przekierowanie do strony wyboru modułu')
        router.get(route('select-module'))
    }

    unprotectedMenuItems.value = backendData.unprotected;
    userName.value = backendData.userName;
    multiModuleApp.value = backendData.multiModuleApp;

    clearInterval(window.displaySessionInterval);
})

//------------------------------------------------------------------------------

onMounted(async () => {
    window.endOfSession = false
    window.showDisplaySessionTime = false;
    window.lastActivityTime = null;
    await initFunctions();
    setExpandableMenu()
})

//------------------------------------------------------------------------------

const stateOfSubmenu = (hasSubmenu, label, active) => {
    submenuState.value[label] = !active
    expandedSubmenu.value[label] = hasSubmenu && submenuState.value[label]
}

//------------------------------------------------------------------------------

const slideDrawer = (state) => {
    if (state) {
        itemVisibility.value = false
        toSlideStyle.value = 'margin-left:0;transition: margin-left 0.2s;'
        document.querySelector('.v-navigation-drawer__content').style.overflowY = 'hidden';
        document.querySelectorAll('.p-submenu-list').forEach((elem) => elem.style.display = "none")
    } else {
        itemVisibility.value = true
        toSlideStyle.value = 'margin-left:200px;transition: margin-left 0.2s;'
        document.querySelector('.v-navigation-drawer__content').style.overflowY = 'auto';
        document.querySelectorAll('.p-submenu-list').forEach((elem) => elem.style.display = "block")
    }
}

//------------------------------------------------------------------------------

const setExpandableMenu = () => {
    expandableMenu.value = Boolean(getSessionValue("user.isExpandableMenu"))
    if (!expandableMenu.value) {
        itemVisibility.value = true
        toSlideStyle.value = 'margin-left:0px;transition: margin-left 0.2s;'
        document.querySelector('.v-navigation-drawer__content').style.overflowY = 'auto';
        document.querySelectorAll('.p-submenu-list').forEach((elem) => elem.style.display = "block")
    } else {
        itemVisibility.value = false
    }
}

//------------------------------------------------------------------------------

const saveSession = () => {
    saveAppSession();
}

//------------------------------------------------------------------------------

const selectModule = () => {
    router.get(route('select-module', { module: getStoreValue('currentModule') }))
}

</script>

<style lang='scss'
       scoped>
    :deep(.p-menuitem) {
        padding-left: 5px;
    }

    :deep(.p-panelmenu .p-panelmenu-content) {
        padding: 0px;
    }

    :deep(.p-submenu-list) {
        padding: 0px;
    }

    :deep(.p-menuitem-link) {
        padding: 0;
    }
</style>
