<template>
    <ConfirmDialog header="Confirm delete"
                   question="Do you want to choose to delete selected file?"
                   v-model="isConfirmationOpen"
                   :operation="onFileRemove"
                   :parameter="fileSelectedToDelete" />

    <v-file-input chips
                  multiple
                  label="Wybierz pliki"
                  single-line
                  variant="solo"
                  class="no-prepend file-input"
                  show-size="1024"
                  :disabled="disabled"
                  :style="style"
                  :class="{ 'dragover': isDragover }"
                  :clearable="false"
                  :error-messages="errorMessage"
                  @update:modelValue="onFileSelect($event)"
                  @drop.prevent="onFileSelect($event.dataTransfer.files)"
                  @dragover.prevent="isDragover = true"
                  @dragenter.prevent="isDragover = false"
                  @dragleave.prevent="isDragover = false"
                  :modelValue="attachments?.hasOwnProperty(category) ? attachments[category] : []">
        <template #selection="{ fileNames }">
            <v-chip class="file-chip"
                    :class="{ 'dragover': isDragover }"
                    v-for="(fileName, index) in fileNames"
                    variant="outlined"
                    :key="index">
                <div>
                    <span @click.stop="openConfirmationWindow(fileName)"
                          class="remove-icon">
                        <i class="fa-solid fa-trash-can"></i>
                    </span>

                    <span v-if="canCreateDownloadLink(fileName)"
                          @click.stop="downloadFile(fileName)"
                          class="download-link">
                        {{ fileName }}
                    </span>
                    <span v-else
                          class="just-added-file">
                        {{ fileName }}
                    </span>
                </div>

            </v-chip>
        </template>
        <template #message="error">
            <div>{{ error.message }}</div>
        </template>
    </v-file-input>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue'
import axios from 'axios'
import { VFileInput } from 'vuetify/components/VFileInput'
import { VChip } from 'vuetify/components/VChip'
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import { getSessionValue } from '@pages/Helper/utils.js'
import { prepareFiles } from '@pages/Helper/crud_utils.js'
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    files: Object,
    style: Object,
    category: String,
    allowedExtensions: Array, //['jpg', 'jpeg', 'png', 'gif'];
    disallowedExtensions: {
        type: Array,
        default: () => ['exe', 'bat', 'sh', 'php', 'html', 'js', 'css']
    },
    maxFiles: Number,
    dataCrud: String,
    disabled: Boolean
})

const attachments = defineModel('files', { default: [] })
const metadata = ref([])
const errorMessage = ref('')
const isDragover = ref(false);
const isConfirmationOpen = ref(false)
const fileSelectedToDelete = ref(null)

//-----------------------------------------------------------------------------------------------

watch(() => attachments.value, (newValue, oldValue) => {
    const files = prepareFiles(newValue)
    if (files.hasOwnProperty(props.category) && Array.isArray(files[props.category])) {
        attachments.value[props.category] = [...files[props.category].map(elem => elem.file)]
        metadata.value[props.category] = [...files[props.category].map(elem => elem.metadata)]
    }
}, { deep: true, immediate: true })

//-----------------------------------------------------------------------------------------------

const onFileSelect = (files) => {
    if (files instanceof FileList) {
        files = Array.from(files);
    }

    if (validateFile(files)) {
        attachments.value[props.category] = [...attachments.value[props.category] ?? [], ...files]
    }
    isDragover.value = false
}

//-----------------------------------------------------------------------------------------------

const onFileRemove = (fileNameWithSize) => {
    const fileToRemove = fileNameWithoutSize(fileNameWithSize)
    attachments.value[props.category] = attachments.value[props.category].filter(file => {
        return file.name !== fileToRemove
    });
    errorMessage.value = null
}

//-----------------------------------------------------------------------------------------------

const validateFile = (newFiles) => {
    if (!newFiles || !Array.isArray(newFiles) || newFiles.length === 0) return true;

    const category = props.category
    const currentFiles = attachments?.value[category] ?? [];
    const totalFiles = (currentFiles?.length ?? 0) + newFiles.length;

    if (props.maxFiles > 0 && totalFiles > props.maxFiles) {
        errorMessage.value = 'Została przekroczona maksymalna ilość plików: ' + props.maxFiles;
        return false;
    }

    if (props.allowedExtensions) {
        const invalidFiles = newFiles.filter(file => {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
            return !props.allowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length > 0) {
            errorMessage.value = 'Dopuszczalne typy plików to: ' + props.allowedExtensions.join(', ');
            return false;
        }
    }

    if (props.disallowedExtensions) {
        const invalidFiles = newFiles.filter(file => {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
            return props.disallowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length > 0) {
            errorMessage.value = 'Próba przesłania plików o niedopuszczalnych typach';
            return false;
        }
    }

    errorMessage.value = null
    return true;
};

//-----------------------------------------------------------------------------------------------

const downloadFile = async (fileNameWithSize) => {
    try {
        const fileName = fileNameWithoutSize(fileNameWithSize)
        const fileMetadata = metadata.value[props.category].find(elem => elem.fileName === fileName)
        const filePath = fileMetadata?.filePath

        if (!filePath) {
            console.error('Nie znaleziono pliku')
            return
        }

        const path = getSessionValue('evinronment.storagePath') + filePath
        const response = await axios.get(path, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Błąd podczas pobierania pliku:', error);
    }
};

//-----------------------------------------------------------------------------------------------

const fileNameWithoutSize = (fileNameWithSize) => {
    return fileNameWithSize.slice(0, fileNameWithSize.lastIndexOf('(')).trim()
}

//-----------------------------------------------------------------------------------------------

const canCreateDownloadLink = (fileNameWithSize) => {
    const fileName = fileNameWithoutSize(fileNameWithSize)
    return (metadata.value[props.category] ?? []).some(elem => elem.fileName === fileName)
}

//-----------------------------------------------------------------------------------------------

const openConfirmationWindow = (fileName) => {
    fileSelectedToDelete.value = fileName
    isConfirmationOpen.value = true
}

//-----------------------------------------------------------------------------------------------

// const closeConfirmationWindow = () => {
//     isConfirmationOpen.value = false
//     fileSelectedToDelete.value = null
// }

//-----------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .remove-icon {
        cursor: pointer;
        color: red;
        margin-right: 5px;
    }

    .download-link {
        cursor: pointer;
        color: blue;
    }

    .just-added-file {
        color: black;
    }

    :deep(.v-input__prepend) {
        display: none;
    }

    .file-input {
        margin-top: 2px !important;
        border: 0;
    }

    :deep(.v-field__input .file-chip) {
        border-radius: 5px;
        border: 1px solid #000;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        margin: 0 5px;
        position: relative;
        z-index: 1
    }


    :deep(.v-field__field input[type="file"]) {
        position: absolute !important;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 0
    }

    :deep(.v-field__field) {
        display: flex;
    }

    .file-input.dragover {
        background-color: lightcyan !important;
        border-radius: 5px;
        border: 1px dotted lightgreen;
    }

    .file-chip.dragover {
        background-color: lightblue !important;
    }

    :deep(.v-input__details) {

        border: 0;
    }
</style>
