<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formSetting"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:30%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="deputy_id"
                                      label="Replace worker"
                                      showClear
                                      :isFormDisabled="false"
                                      :options="select?.managers_below_me"
                                      v-model:field="deputy.deputy_id" />
                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Set')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="saveDeputy"></Button>
                <Button :label="__('Delete')"
                        icon="pi pi-trash"
                        class="p-button-danger"
                        style="margin:10px 5px;"
                        @click="removeDeputy"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="cancelOperation">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormDropdown from "@crud/FormDropdown.vue";
import Button from 'primevue/button';
import { useForm, router } from '@inertiajs/vue3';
import { __, setStoreValue, getField } from '@pages/Helper/utils.js'
import { prepareSelect } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const isLoading = ref(true);
const options = ["managers_below_me"];
const select = ref(null);

const deputy = ref(props.data)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(options, null, deputy)
    isLoading.value = false;
});

// -----------------------------------------------------

const saveDeputy = () => {
    useForm({ ...deputy.value }).get(route('set-deputy'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: async () => {
            const deputyName = await getField('workers', ['last_name', 'first_name'], "workers.id='" + deputy.value.deputy_id + "'")
            setStoreValue('deputyName', deputyName)
        },
        onFinish: () => {
        }
    })
}

// -----------------------------------------------------

const removeDeputy = () => {
    useForm({ ...deputy.value }).get(route('remove-deputy'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            setStoreValue('deputyName', null)
        },
        onFinish: () => {
        }
    })
}

// -----------------------------------------------------

const cancelOperation = () => {
    router.get(route('worker-index'));
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .shadow-box {
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    }
</style>
