<template>
    <v-form name="copyForm">
        <div class="grid">
            <FormMultiselect fieldName="worker_id"
                             label="Worker"
                             showClear
                             size="4"
                             validation="required"
                             :options="select?.myactiveworkers"
                             v-model:field="worker_id" />

            <FormDropdown fieldName="period"
                          label="Assessment period"
                          showClear
                          size="3"
                          validation="required"
                          :filtering="false"
                          :options="currentPeriod[0] == 1 ? select?.month_periods : select?.quarter_periods"
                          v-model:field="period" />
            <FormInputNumber fieldName="year"
                             label="Year"
                             size="2"
                             validation="required"
                             :useGrouping="false"
                             :min="2011"
                             :isFocus="true"
                             v-model:field="year" />
            <FormDropdown fieldName="status_id"
                          label="Status"
                          size="3"
                          showClear
                          validation="required"
                          :filtering="false"
                          :options="select?.short_statuses"
                          v-model:field="status_id" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="copySheet">
                <i class="fa-solid fa-plus"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Copy sheet') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import { validation, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    sheetId: Number,
    currentPeriod: String,
    formProps: Object
})

const isCopySheetModalOpen = defineModel('isCopySheetModalOpen')
const worker_id = ref(null)
const period = ref(null)
const periodList = ref(null)
const year = ref(new Date().getFullYear())
const status_id = ref(null)

// -----------------------------------------------------

const isLoading = ref(true);
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    if (props.currentPeriod[0] == 1) {
        periodList.value = 'months';
    } else {
        periodList.value = 'quarters';
    }

    select.value = await prepareSelect(['myactiveworkers', 'quarter_periods', 'month_periods', 'short_statuses']);
})

// -----------------------------------------------------

const cancel = () => {
    isCopySheetModalOpen.value = false
}
// -----------------------------------------------------

const copySheet = async () => {
    const formData = { worker_id: worker_id.value, period: period.value, year: year.value, status_id: status_id.value, sheet_id: props.sheetId }
    if (!validation(formData, 'copyForm')) {
        return false
    }

    const response = await getFromRoute('sheet-copy', { ...formData }, 'post', 'copyForm');
    if (response.status == 'success') {
        props.formProps.getLazyData()
        isCopySheetModalOpen.value = false
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 5 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 10 });
    }
}
// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
