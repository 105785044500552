<template>

    <Head :title="__('Login')" />

    <div class="auth-container">
        <v-form name="formLogin"
                class="auth-form"
                @keyup.enter="login">
            <div class="auth-top">
                <v-col class="auth-logo">
                    <Image :src="systemLogo" />
                </v-col>
            </div>

            <div class="auth-main">
                <v-col class="auth-title">
                    {{ __('Login') }}
                </v-col>

                <v-col v-if="errorGa2FaLogin"
                       style="text-align:center;font-size:20px;color:red;">
                    {{ __('Invalid code specified in the second security level') }}
                </v-col>
                <v-col v-if="errorAzure"
                       style="text-align:center;font-size:20px;color:red;">
                    {{ errorAzure }}
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="email">{{ __('E-Mail Address') }}</label>
                    <InputText type="text"
                               id="email"
                               size="small"
                               style="display:flex;width:100%"
                               :class="{ 'invalid-auth-input': form.errors.email }"
                               @input="form.errors.email = null"
                               v-model="form.email" />
                    <div v-if="form.errors.email"
                         class="invalid-auth-form">{{ __(form.errors.email) }}</div>
                    <div v-if="form.errors.timesToLock"
                         class="invalid-auth-form">{{ __(form.errors.timesToLock) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('Password') }}</label>
                    <inputText type="password"
                               id="password"
                               size="small"
                               v-model="form.password"
                               :class="{ 'invalid-auth-input': form.errors.password }"
                               @input="form.errors.password = null" />
                    <div v-if="form.errors.password"
                         class="invalid-auth-form">{{ __(form.errors.password) }}</div>
                </v-col>

                <v-col>
                    <v-row class="justify-content-between">
                        <div>
                            <Checkbox inputId="remember"
                                      v-model="form.remember"
                                      binary />
                            <label for="remember"
                                   class="auth-label ml-2"> {{ __('Remember Me') }} </label>
                        </div>
                    </v-row>
                </v-col>

                <v-col gap="2">
                    <v-row class="justify-content-between">
                        <div>
                            <Checkbox inputId="removeSession"
                                      v-model="form.removeSession"
                                      binary />
                            <label for="removeSession"
                                   class="auth-label ml-2"> {{ __('Remove session data') }} </label>
                        </div>
                    </v-row>
                </v-col>

                <v-row class="justify-content-between">
                    <Button @click="login"
                            class="auth-button"
                            label="Login"
                            icon="pi pi-sign-in" />

                    <Button v-if="isLoginViaGithub"
                            @click="githubLogin"
                            class="auth-button"
                            label="Github"
                            severity="info"
                            icon="pi pi-github" />

                    <Button v-if="isLoginViaAzure"
                            @click="azureLogin"
                            class="auth-button"
                            label="Azure"
                            severity="warning"
                            icon="pi pi-microsoft" />
                </v-row>

                <div class="auth-link-outer">
                    <i class="pi pi-star"
                       style="font-size: 1rem; padding:10px;"></i>
                    <Link :href="route('password-forget')"
                          class="auth-link-inner"
                          v-if="route().has('password-forget')">{{ __('Forgot Your Password?') }}</Link>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';

import Layout from '@layouts/LayoutNoMenuNoFooter.vue'
import Image from '@helper/Image.vue'
import { __, setStoreValue, getStoreValue, getSessionValue, setSessionValue, getFromRoute } from '@helper/utils.js'
import { systemLogo, startSplash } from '@helper/constants.js'

defineOptions({ layout: Layout })

const props = defineProps({
    errorGa2FaLogin: Boolean,
    errorAzure: String,
})

const isLoginViaGithub = false
const isLoginViaAzure = false

// -----------------------------------------------------

const page = usePage();
const form = useForm({ email: '', password: '', remember: false, removeSession: false })

// -----------------------------------------------------

setStoreValue('appCaption', page.props.appCaption)

const isReloaded = getSessionValue("reloaded")
if (!isReloaded) {
    setSessionValue("reloaded", true)
}

// -----------------------------------------------------

const login = () => {
    form.post(route('login'))
    setSessionValue("reloaded", false)
}

// -----------------------------------------------------

const azureLogin = () => {
    window.location.href = '/azure/login';
    setSessionValue("reloaded", false)
}

// -----------------------------------------------------

const githubLogin = () => {
    window.location.href = '/github/login';
    setSessionValue("reloaded", false)
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
