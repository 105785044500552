<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :buttonsVisibility="buttonsVisibility"
            :rowButtonAction="rowButtonAction"
            v-model:selectedRows="selectedRows">

        <template #crud="formProps">
            <DeptForm :formProps="formProps">
            </DeptForm>
        </template>
        <template #search="searchProps">
            <DeptSearch :searchProps="searchProps">
            </DeptSearch>
        </template>
    </v-view>

</template>

<script setup>
import { ref } from "vue";
import DeptForm from './DeptForm.vue'
import DeptSearch from './DeptSearch.vue'
import ImportForm from '@crud/ImportForm.vue'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { __ } from '@pages/Helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
})

// const isImportModalOpen = ref(false)
// const importFormProps = ref({})

//------------------------------------------------------------------------------

// const importModalControl = {
//     open: () => isImportModalOpen.value = true,
//     close: () => isImportModalOpen.value = false,
// }

// //------------------------------------------------------------------------------

// const actionImportButton = (formProps) => {
//     importFormProps.value = formProps
//     importModalControl.open()
// }

// //------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
