<template>
    <div class="field"
         :class="colSize">
        <label class="block"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <ToggleButton :disabled="isFormDisabled"
                      :name="fieldName"
                      :onLabel="onLabel"
                      :offLabel="offLabel"
                      :iconPos="iconPos"
                      :style="field ? onStyle : offStyle"
                      :class="field ? onClass : offClass"
                      @change="change"
                      v-focus="isFocus"
                      v-model="parseField">
            <template #icon="scope">
                <span v-if="scope.value"
                      :style="onIconStyle"
                      :class="onIconClass"></span>
                <span v-if="!scope.value"
                      :style="offIconStyle"
                      :class="offIconClass"></span>
            </template>
        </ToggleButton>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ToggleButton from 'primevue/togglebutton';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    onLabel: {
        type: String,
        default: null
    },
    offLabel: {
        type: String,
        default: null
    },
    iconPos: {
        type: String,
        default: 'left'
    },
    onStyle: {
        type: [Object, String],
        default: null
    },
    offStyle: {
        type: [Object, String],
        default: null
    },
    onClass: {
        type: String,
        default: 'toggle-button-on'
    },
    offClass: {
        type: String,
        default: 'toggle-button-off'
    },
    onIconStyle: {
        type: [Object, String],
        default: "margin-right:7px;"
    },
    offIconStyle: {
        type: [Object, String],
        default: "margin-right:7px;"
    },
    onIconClass: {
        type: String,
        default: 'pi pi-check'
    },
    offIconClass: {
        type: String,
        default: 'pi pi-times'
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
    change: {
        type: Function,
        required: false
    },
    default: {
        type: Boolean,
        default: false
    }
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';

const onLabel = ref(props.onLabel ?? __('Yes'))
const offLabel = ref(props.offLabel ?? __('No'))

const parseField = computed({
    get: () => {
        if (field.value === undefined) {
            field.value = props.default
        }
        return Boolean(field.value)
    },
    set: newValue => {
        field.value = newValue
        return Boolean(field.value)
    }
});

</script>

<style lang="scss"
       scoped>

    :deep(.p-togglebutton .p-button) {
        background-color: transparent;
        color: #000
    }
</style>
