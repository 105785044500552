<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :rowButtonAction="rowButtonAction"
            :buttonsVisibility="buttonsVisibility"
            v-model:selectedRows="selectedRows">

        <template #crud="formProps">
            <LoguserForm :formProps="formProps">
            </LoguserForm>
        </template>
        <template #search="searchProps">
            <LoguserSearch :searchProps="searchProps">
            </LoguserSearch>
        </template>
    </v-view>
</template>

<script setup>
import LoguserForm from './LoguserForm.vue'
import LoguserSearch from './LoguserSearch.vue'
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
})

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
