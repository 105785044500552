<template>
    <v-form>
        <div class="grid">
            <FormDropdown fieldName="premium_group_id"
                          label="Premium group"
                          validation="required"
                          size="12"
                          :change="checkPremiumGroup"
                          :filtering="false"
                          :options="select?.manager_premium_groups"
                          v-model:field="premium_group_id" />
        </div>
        <div class="grid">
            <FormInputNumber fieldName="premium_year"
                             label="Year"
                             size="2"
                             validation="required"
                             :useGrouping="false"
                             :min="2011"
                             :isFocus="true"
                             v-model:field="premium_year" />

            <FormDropdown fieldName="premium_quarter"
                          label="Quarter"
                          size="5"
                          validation="required"
                          :isFormDisabled="assessmentPeriod == 1"
                          :filtering="false"
                          :setFirst="false"
                          :options="select?.quarter_periods"
                          v-model:field="premium_quarter" />

            <FormDropdown fieldName="premium_month"
                          label="Month"
                          size="5"
                          validation="required"
                          :isFormDisabled="assessmentPeriod == 3"
                          :filtering="false"
                          :options="select?.month_periods"
                          v-model:field="premium_month" />

        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="generateSheets">
                <i class="fa-solid fa-plus"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Generate') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import { validation, onFilterService, initForm, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getStoreValue, getFromRoute, currentYear, currentQuarter, currentMonth } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isGenerateSheetsModalOpen = defineModel('isGenerateSheetsModalOpen')
const isLoading = ref(true);
const premium_group_id = ref(null)
const premium_year = ref(null)
const premium_quarter = ref(null)
const premium_month = ref(null)
const assessmentPeriod = ref(null)
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(['manager_premium_groups', "quarter_periods", "month_periods"], null, null, { 'is_generate_sheets': true });
    const currentPeriod = getStoreValue('premiumPeriod')
    isLoading.value = false;

    premium_year.value = currentPeriod?.premium_year ?? currentYear()
    premium_quarter.value = currentPeriod?.premium_quarter ?? currentQuarter(true)
    premium_month.value = currentPeriod?.premium_month ?? currentMonth(true)
})

// -----------------------------------------------------

const cancel = () => {
    isGenerateSheetsModalOpen.value = false
}
// -----------------------------------------------------

const generateSheets = async () => {
    const formData = {
        premium_group_id: premium_group_id.value,
        premium_year: premium_year.value,
        premium_quarter: premium_quarter.value,
        premium_month: premium_month.value,
        selectedRows: props.formProps.selectedRows
    }
    if (!validation(formData)) {
        return false
    }

    const response = await getFromRoute('worker-generate-sheets', formData, 'post');
    screenMessage({ message: response.message, title: "Information", type: response.status == 'success' ? "info" : "error", position: "center", timeout: 5 });

    props.formProps.clearSelection()
    props.formProps.getLazyData()

    // useForm(formData).post(route('worker-generate-sheets'), {
    //     preserveState: true,
    //     preserveScroll: true,
    //     forceFormData: true,
    //     onError: (errors) => {
    //         console.error(errors)
    //     },
    //     onSuccess: () => {
    //         const message = {
    //             message: "Sheets was generated",
    //             title: "Information",
    //             type: "info",
    //             position: "center",
    //             timeout: 5,
    //         };
    //         screenMessage(message);
    //     },
    //     onFinish: () => {
    //         props.formProps.clearSelection()
    //         props.formProps.getLazyData()
    //     }
    // })

    isGenerateSheetsModalOpen.value = false
}

// -----------------------------------------------------

const checkPremiumGroup = async () => {
    const period = await getFromRoute('premium-period', { premiumId: premium_group_id.value })
    assessmentPeriod.value = parseInt(period?.assessment_period)
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
