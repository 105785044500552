<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formConditions"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:60%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Report parameters')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormInputNumber fieldName="year"
                                         label="Year"
                                         size="1"
                                         validation="required"
                                         :isFocus="true"
                                         :useGrouping="false"
                                         :default="new Date().getFullYear()"
                                         v-model:field="conditions.year" />

                        <FormDropdown fieldName="premium_period"
                                      label="Premium period"
                                      :showClear="true"
                                      size="3"
                                      validation="required"
                                      :filtering="false"
                                      :options="select?.premium_periods"
                                      v-model:field="conditions.premium_period" />
                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Create')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="createReport"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="cancelOperation">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormMultiselect from "@crud/FormMultiselect.vue";
import FormInputNumber from "@crud/FormInputNumber.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { __, makeReport } from '@pages/Helper/utils.js'
import { prepareSelect, onFilterService, validation } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    reportParams: Object,
    conditions: { type: Object, default: {} }
})

const isLoading = ref(true);
const options = ["premium_periods"];
const select = ref(null);

const conditions = ref(props.conditions)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(options, null, conditions)
    isLoading.value = false;
});

// -----------------------------------------------------

const createReport = () => {
    if (validation(conditions.value, 'formConditions')) {
        makeReport(props.reportParams, conditions, isLoading)
    }
}

// -----------------------------------------------------

const cancelOperation = () => {
    router.get(route('report-index'));
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
