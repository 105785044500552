<template>
    <div class="formgroup-inline mt-5"
         :class="[colSize, outerClass, outerRepeaterClass]"
         :style="outerStyle">
        <Checkbox class="field-checkbox"
                  :class="[innerClass, innerRepeaterClass]"
                  :style="innerStyle"
                  :binary="true"
                  :disabled="isFormDisabled"
                  :name="fieldName"
                  @change="change"
                  v-focus="isFocus"
                  v-model="field" />
        <label v-if="label"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Checkbox from 'primevue/checkbox';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    innerClass: {
        type: String,
        default: null
    },
    outerClass: {
        type: String,
        default: null
    },
    innerStyle: {
        type: [Object, String],
        default: null
    },
    outerStyle: {
        type: [Object, String],
        default: null
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
    change: {
        type: Function,
        required: false
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';
const innerRepeaterClass = ref(null)
const outerRepeaterClass = ref(null)

if (Number.isInteger(field.value)) {
    field.value = field.value == 1 ? true : false;
}

if (props.inRepeater) {
    innerRepeaterClass.value = 'repeater-checkbox-inner';
    outerRepeaterClass.value = 'repeater-checkbox-outer';
}

</script>

<style lang="scss"
       scoped></style>
