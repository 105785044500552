<template>
    <Button v-if="!buttonsVisibility?.deny?.includes(name) && (buttonsVisibility?.allow?.includes(name) || unprotected.includes(formName, '.' + name) || hasPermission([formName, name]))"
            v-ripple
            v-tooltip.top="__(tooltip)"
            @click="click"
            :disabled="buttonProps.sourceForm.processing"
            :style="style"
            :class="className"
            :severity="severity">
        <slot>
        </slot>
    </Button>
</template>

<script setup>
import Button from 'primevue/button';
import { __, hasPermission } from '@pages/Helper/utils.js'

const props = defineProps({
    buttonProps: Object,
    name: {
        type: String,
        default: ''
    },
    tooltip: {
        type: String,
        default: ''
    },
    click: {
        type: Function,
        default: null
    },
    severity: {
        type: String,
        default: 'primary'
    },
    buttonsVisibility: {
        type: Object,
        default: () => {
            return {
                allow: [],
                deny: []
            }
        }
    },
    style: {
        type: [String, Object],
        default: ''
    },
    className: {
        type: String,
        default: ''
    }
})

const formName = props.buttonProps.formProps.formName;
const unprotected = props.buttonProps.formProps.unprotected ?? [];
</script>

<style lang="scss"
       scoped></style>
