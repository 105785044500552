<template>
    <v-form :name="searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="dept_name"
                                   label="Name in structure"
                                   size="10"
                                   :isFocus="true"
                                   v-model:field="search.dept_name" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="2"
                                   v-model:field="search.symbol" />

                    <FormTreeSelect fieldName="parent_id"
                                    label="Upper level"
                                    size="10"
                                    showClear
                                    selectionMode="multiple"
                                    :options="select?.dept_symbols_tree"
                                    :filter="(event) => onFilter(event, 'dept_symbols_tree')"
                                    v-model:field="search.parent_id" />

                    <FormInputText fieldName="dept_sapid"
                                   label="SAP ID"
                                   size="2"
                                   v-model:field="search.dept_sapid" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormTreeSelect from '@crud/FormTreeSelect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)
const options = ["dept_symbols_tree"];
const select = ref(null);

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
