<template>
    <v-form>
        <div class="grid">
            <FormInputNumber fieldName="premium_year"
                             label="Year"
                             size="2"
                             :useGrouping="false"
                             :min="2011"
                             :isFocus="true"
                             v-model:field="premium_year" />

            <FormDropdown fieldName="premium_quarter"
                          label="Quarter"
                          size="5"
                          :isFormDisabled="assessmentPeriod == 1"
                          :filtering="false"
                          :setFirst="false"
                          :options="select?.quarter_periods"
                          v-model:field="premium_quarter" />

            <FormDropdown fieldName="premium_month"
                          label="Month"
                          size="5"
                          :isFormDisabled="assessmentPeriod == 3"
                          :filtering="false"
                          :options="select?.month_periods"
                          v-model:field="premium_month" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="createNewSheet">
                <i class="fa-solid fa-plus"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Create') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import { validation, onFilterService, initForm, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getStoreValue, currentDate, currentYear, currentQuarter, currentMonth, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isNewSheetConditionModalOpen = defineModel('isNewSheetConditionModalOpen')
const initialConditions = defineModel('initialConditions')

const isLoading = ref(true);
const premium_year = ref(null)
const premium_quarter = ref(null)
const premium_month = ref(null)
const version_date = ref(props.formProps.versionDate)
const sheet_number = ref(null)
const assessmentPeriod = ref(null)
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(["quarter_periods", "month_periods"]);
    const currentPeriod = getStoreValue('premiumPeriod')
    isLoading.value = false;
    await checkPremiumGroup()

    premium_year.value = currentPeriod?.premium_year ?? currentYear()
    premium_quarter.value = currentPeriod?.premium_quarter ?? currentQuarter(true)
    premium_month.value = currentPeriod?.premium_month ?? currentMonth(true)
})

// -----------------------------------------------------

const checkPremiumGroup = async () => {
    const period = await getFromRoute('premium-period', { premiumId: props.formProps.premiumId })
    assessmentPeriod.value = parseInt(period?.assessment_period)
}

// -----------------------------------------------------

const cancel = () => {
    isNewSheetConditionModalOpen.value = false
}
// -----------------------------------------------------

const createNewSheet = async () => {
    const canCreate = await checkIfCanCreateSheet()
    if (!canCreate) {
        return false
    }

    initialConditions.value = {
        premium_year: premium_year.value,
        premium_quarter: premium_quarter.value,
        premium_month: premium_month.value,
        version_date: version_date.value,
        sheet_number: sheet_number.value,
    }
    isNewSheetConditionModalOpen.value = false
    props.formProps.openModalAdd()
}

// -----------------------------------------------------

const checkIfCanCreateSheet = async () => {
    const checkNewSheetConditions = await getFromRoute('sheet-can-create', { workerId: props.formProps.workerId, premiumYear: premium_year.value, premiumQuarter: premium_quarter.value, premiumMonth: premium_month.value }, 'post')

    if (!checkNewSheetConditions || checkNewSheetConditions.sheetExists || !checkNewSheetConditions.sheetInRange || !checkNewSheetConditions.premiumGroupDefined) {
        const message = {
            message: checkNewSheetConditions?.msg || __("Cannot create sheet for given period"),
            title: __("Cannot create sheet for given period"),
            type: "error",
            position: "center",
            timeout: 5,
        };

        screenMessage(message);
        return false
    }
    sheet_number.value = checkNewSheetConditions.sheetNumber
    return true
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
