<template>
    <div>
        <ConfirmDialog header="Confirm operation"
                       question="Do you want to approve/reject sheets?"
                       v-model="isConfirmationApproveRejestOpen"
                       :operation="approveRejectSheets"
                       :parameter="approveRejectParameters" />

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :editFieldCondition="editFieldCondition"
                :onRowEditSave="onRowEditSave"
                :rowButtonAction="rowButtonAction"
                :buttonsVisibility="buttonsVisibility">
            <template #buttons="formProps">
                <BackButton tooltip="Back to workers list"
                            routeName="worker-index" />

                <Button @click.stop="openConfirmationApproveRejectWindow(formProps)"
                        style="background-color:green;color:white;margin:0 7px 0 0;white-space:nowrap"
                        severity="info">
                    <i class="fa-solid fa-stamp"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Approve/Reject sheets') }}
                </Button>

            </template>

            <template #crud="formProps">
                <ApprovalForm :formProps="formProps"> </ApprovalForm>
            </template>
            <!-- <template #search="searchProps">
                <SheetSearch :searchProps="searchProps"> </SheetSearch>
            </template> -->
        </v-view>
    </div>
</template>

<script setup>
import { ref } from "vue";
import BackButton from '@pages/Crud/BackButton.vue';
import ApprovalForm from "./ApprovalForm.vue";
import Button from "primevue/button";
//import SheetSearch from "../Sheet/SheetSearch.vue";
import { rowButtonAction } from "@pages/Helper/crud_utils.js";
import { __ } from "@pages/Helper/utils.js";
import { getFromRoute, screenMessage } from "../../../Helper/utils";
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    workerId: Number,
    premiumId: Number,
    versionDate: String,
    currentDate: String,
    buttonsVisibility: Object,
    sheetIds: Array,
    maxRejectionCount: Number,
});

const isConfirmationApproveRejestOpen = ref(false)
const approveRejectParameters = ref(null)
const canSaveRowEditing = ref(true)

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    return value;
};

//------------------------------------------------------------------------------

const editFieldCondition = (field, data) => {
    if (data.rejected_count >= props.maxRejectionCount) {
        screenMessage({ message: "Max number of rejections exceeded", title: "Information", type: "warning", position: "center", timeout: 5 }, false)
        canSaveRowEditing.value = false
        return false
    }
    canSaveRowEditing.value = true
    return true
};

//------------------------------------------------------------------------------

const onRowEditSave = (event, columns) => {
    return canSaveRowEditing.value;
};

//------------------------------------------------------------------------------

const openConfirmationApproveRejectWindow = (formProps) => {
    isConfirmationApproveRejestOpen.value = true
    approveRejectParameters.value = formProps
}

//------------------------------------------------------------------------------

const approveRejectSheets = async (formProps) => {
    const response = await getFromRoute("sheet-approve-reject", { 'sheet_ids': props.sheetIds }, 'post');
    formProps.getLazyData();

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 5 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 5 });
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
