<template>
    <div class="card flex justify-content-center">
        <Toast :position="position" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3'
import { ref, watch } from 'vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { __ } from '@pages/Helper/utils.js'

const toast = useToast();
const page = usePage();

const severity = ['success', 'info', 'warn', 'error', 'secondary', 'contrast'];

let message = ref(null);
let title = ref(null);
let type = ref(null);
let position = ref(null);
let timeout = ref(null);

watch(() => page, (newPage) => {
    if (newPage?.props?.flash?.message) {
        message.value = newPage?.props?.flash?.message?.message;
        title.value = newPage?.props?.flash?.message?.title ?? '';
        type.value = newPage?.props?.flash?.message?.type;
        position.value = newPage?.props?.flash?.message?.position;
        timeout.value = newPage?.props?.flash?.message?.timeout;
        if (!severity.includes(type.value)) {
            type.value = 'info';
        }
        if (message.value) {
            toast.add({ severity: type.value, summary: __(title.value), detail: __(message.value), life: timeout.value * 1000 });
        }
    }

    // if (newPage?.props?.flash?.message?.type === 'remove-message') {
    //     toast.remove(newPage?.props?.flash?.message?.id)
    // }
}, { deep: true });

</script>

<style scoped></style>
