<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formConditions"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:30%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Make chart data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormMultiselect fieldName="dept_id"
                                         label="Select departments"
                                         showClear
                                         validation="required"
                                         :isFormDisabled="false"
                                         :options="select?.depts"
                                         :filter="(event) => onFilter(event, 'depts')"
                                         v-model:field="conditions.dept_id" />
                        <FormInputNumber fieldName="premium_year"
                                         label="Year"
                                         size="2"
                                         validation="required"
                                         :useGrouping="false"
                                         :min="2011"
                                         :default="new Date().getFullYear()"
                                         :isFocus="true"
                                         v-model:field="conditions.premium_year" />
                        <FormDropdown fieldName="premium_period"
                                      label="Assessment period"
                                      size="5"
                                      validation="required"
                                      :filtering="false"
                                      :setFirst="false"
                                      :options="select?.premium_periods"
                                      v-model:field="conditions.premium_period" />

                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Create')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="createChart"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="router.get(route('chart-index'))">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormMultiselect from "@crud/FormMultiselect.vue";
import FormInputNumber from "@crud/FormInputNumber.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { __, makeChart, convertStringsToIntegers } from '@pages/Helper/utils.js'
import { prepareSelect, onFilterService, validation } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    chartParams: Object,
    conditions: { type: Object, default: {} }
})

const isLoading = ref(true);
const options = ["depts", "premium_periods"];
const select = ref(null);

const conditions = ref(props.conditions)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    //conditions.value.dept_id = convertStringsToIntegers(conditions.value.dept_id);
    select.value = await prepareSelect(options, null, conditions)
    isLoading.value = false;
});

// -----------------------------------------------------

const createChart = () => {
    if (validation(conditions.value, 'formConditions')) {
        makeChart(props.chartParams, conditions, isLoading)
    }
}

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, conditions, null, ['depts']);
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
