<template>
    <!-- :buttons="{ 'deny': ['delete', 'deleterow'] }" -->
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :rowButtonAction="rowButtonAction"
                :beforeOpenAdd="beforeOpenAdd"
                :buttonsVisibility="buttonsVisibility">
            <template #buttons>
                <BackButton tooltip="Back to workers list"
                            :routeName="previousRoute" />
            </template>

            <template #crud="formProps">
                <SheetForm :formProps="formProps"
                           :fromSheetsForWorker="true"
                           :workerId="workerId"
                           :initialConditions="initialConditions"> </SheetForm>
            </template>
            <template #search="searchProps">
                <SheetSearch :searchProps="searchProps"> </SheetSearch>
            </template>
        </v-view>

        <Dialog modal
                :visible="isNewSheetConditionModalOpen"
                v-model:visible="isNewSheetConditionModalOpen"
                :style="{ width: '500px' }">
            <template #header>
                <div class='form-header'
                     :style="{ backgroundColor: 'transparent' }"
                     v-html="newSheetHeader"></div>
            </template>
            <NewSheetConditionForm :formProps="newSheetConditionProps"
                                   v-model:isNewSheetConditionModalOpen="isNewSheetConditionModalOpen"
                                   v-model:initialConditions=initialConditions>
            </NewSheetConditionForm>
        </Dialog>

    </div>
</template>

<script setup>
import { ref } from "vue";
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import BackButton from '@pages/Crud/BackButton.vue';
import SheetForm from "./SheetForm.vue";
import SheetSearch from "./SheetSearch.vue";
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Dialog from 'primevue/dialog';
import NewSheetConditionForm from "./NewSheetConditionForm.vue";
import { __, screenMessage, currentDate } from "@pages/Helper/utils.js";
import { rowButtonAction } from "@pages/Helper/crud_utils.js";

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    workerId: Number,
    premiumId: Number,
    versionDate: String,
    currentDate: String,
    buttonsVisibility: Object,
    previousRoute: String,
});

const isNewSheetConditionModalOpen = ref(false)
const newSheetConditionProps = ref({})
const initialConditions = ref({})
const versionDate = ref(props.versionDate ?? props.currentDate)
const newSheetHeader = __('Give conditions on day') + ": <span style='color:red'>" + (versionDate.value) + "</span>"
const previousRoute = ref(props.previousRoute ?? 'worker-index')

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    return value;
};

//------------------------------------------------------------------------------

const beforeOpenAdd = (openModalFunc) => {
    newSheetConditionProps.value = {
        openModalAdd: openModalFunc,
        workerId: props.workerId,
        premiumId: props.premiumId,
        versionDate: versionDate.value,
    }
    isNewSheetConditionModalOpen.value = true
}

//------------------------------------------------------------------------------


</script>

<style lang="scss"
       scoped></style>
