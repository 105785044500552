<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :buttonsVisibility="buttonsVisibility"
            :rowButtonAction="rowButtonAction"
            :renderColumnValue="renderColumnValue"
            v-model:selectedRows="selectedRows">

        <template #crud="formProps">
            <EventForm :formProps="formProps">
            </EventForm>
        </template>
        <template #search="searchProps">
            <EventSearch :searchProps="searchProps">
            </EventSearch>
        </template>

    </v-view>
</template>

<script setup>
import EventForm from './EventForm.vue'
import EventSearch from './EventSearch.vue'
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    if (field == 'percent_value') {
        return value + '%'
    }
    return value
}

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
