import { defineAsyncComponent } from "vue";

export const loadDynamicComponent = async (component) => {
    let dynamicComponent = null
    switch (component) {
        case 'DynamicForm':
            //dynamicComponent = await defineAsyncComponent(() => import('@pages/App/Database/Components/DynamicForm.vue'));
            break;
    }
    return dynamicComponent
}
