<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <v-form name="formUserProfile"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:55%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormInputText fieldName="last_name"
                                       label="Last name"
                                       size="3"
                                       :isFormDisabled="true"
                                       :isFocus="true"
                                       v-model:field="user.last_name" />

                        <FormInputText fieldName="first_name"
                                       label="First name"
                                       size="3"
                                       :isFormDisabled="true"
                                       v-model:field="user.first_name" />

                        <FormInputText fieldName="email"
                                       label="Email"
                                       size="3"
                                       :isFormDisabled="true"
                                       v-model:field="user.email" />

                        <FormInputMask fieldName="phone"
                                       label="Phone"
                                       size="3"
                                       mask="(+99) 999-999-999"
                                       placeholder="(+99) 999-999-999"
                                       v-model:field="user.phone" />

                        <FormPassword fieldName="password"
                                      label="Password"
                                      size="3"
                                      v-model:field="user.password" />

                        <FormPassword fieldName="retype_password"
                                      label="Retype password"
                                      size="3"
                                      v-model:field="retypePassword" />

                        <FormRadioButton fieldName="menu"
                                         label="Menu"
                                         size="2"
                                         :data="menuTypes"
                                         v-model:field="user.menu" />
                        <FormInputToggleButton v-if="user.menu == 'V'"
                                               fieldName="is_expandable_menu"
                                               label="Hiddenable side menu"
                                               size="2"
                                               v-model:field="user.is_expandable_menu" />

                        <FormRadioButton fieldName="page_length"
                                         label="Page length"
                                         size="3"
                                         :data="select?.page_length"
                                         v-model:field="user.page_length" />

                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button @click="saveData"
                        :disabled="user.processing"
                        style="margin:0 5px"
                        severity="success">
                    {{ __('Save') }}
                </Button>
                <Button @click="cancelData"
                        :disabled="user.processing"
                        style="margin:0 5px"
                        severity="secondary">
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormInputMask from '@crud/FormInputMask.vue'
import FormPassword from '@crud/FormPassword.vue'
import Button from 'primevue/button';
import { useForm, router, usePage } from '@inertiajs/vue3';
import { onErrorService, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const options = ["page_length"];
const select = ref(null);
const user = ref(props.data)
const retypePassword = ref("");

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(options)
});

// -----------------------------------------------------

const beforeSave = (sourceForm) => {
    if (sourceForm.password !== retypePassword.value) {
        const message = {
            message: "Passwords not match",
            title: "Password error",
            type: "error",
            position: "center",
            timeout: 5,
        };

        screenMessage(message);
        return false;
    }
    return true;
};

// -----------------------------------------------------

const saveData = () => {
    const success = beforeSave(user.value);
    if (success) {
        useForm(user.value).post(route('user-update-profile'), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => {
                onErrorService(errors)
            },
        })
    }
}

// -----------------------------------------------------

const cancelData = () => {
    router.get(route('system-start'));
}

// -----------------------------------------------------

const menuTypes = [
    { label: 'poziome', value: 'H' },
    { label: 'pionowe', value: 'V' },
]

// -----------------------------------------------------

const languages = [
    { label: 'polski', value: 'pl' },
    { label: 'angielski', value: 'en' },
]

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .shadow-box {
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    }
</style>
