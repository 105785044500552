<template>
    <v-form :name="searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="rate_name"
                                   label="Rate name"
                                   size="10"
                                   :isFocus="true"
                                   v-model:field="search.rate_name" />

                    <FormInputNumber fieldName="year"
                                     label="Year"
                                     size="2"
                                     :useGrouping="false"
                                     :min="2024"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     v-model:field="search.year" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)

let search = ref({})
prepareSearch(search, props)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
