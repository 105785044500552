<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Textarea class="w-full"
                  :rows="rowsAsNumber"
                  :cols="colsAsNumber"
                  :autoresize="autoresize"
                  :disabled="isFormDisabled"
                  :name="fieldName"
                  :validation="validation"
                  :style="inputStyle"
                  :class="{ 'required-field': hasRequiredAttr, [inputClass]: true }"
                  v-focus="isFocus"
                  v-model="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Textarea from 'primevue/textarea';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inputClass: {
        type: String,
        default: null
    },
    inputStyle: {
        type: [Object, String],
        default: null
    },
    autoresize: {
        type: Boolean,
        default: true
    },
    rows: {
        type: [Number, String],
        default: 5
    },
    cols: {
        type: [Number, String],
        default: 30
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const rowsAsNumber = Number(props.rows);
const colsAsNumber = Number(props.cols);
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped></style>
