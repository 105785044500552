<template>
    <Dialog class="form-modal"
            v-model:visible="isVisibleDialog"
            :closable="true"
            :modal="false"
            contentStyle=""
            contentClass=""
            :style="{ width: '700px' }"
            :breakpoints="{}"
            @hide="hideDialog"
            :pt="{
                header: {
                    style: {},
                    class: '',
                },
                footer: {
                    style: {},
                    class: '',
                },
            }">
        <template #header>
            <span v-html="formHeader">
            </span>
        </template>

        <v-form name="setingPremiumPeriodForm">
            <div class="grid">
                <FormInputNumber fieldName="premium_year"
                                 label="Year"
                                 size="2"
                                 :useGrouping="false"
                                 :min="2011"
                                 :isFocus="true"
                                 @keyup.enter="autoSaveDialog"
                                 v-model:field="premium_year" />

                <FormDropdown fieldName="premium_quarter"
                              label="Quarter"
                              size="5"
                              :filtering="false"
                              :setFirst="false"
                              :options="select?.quarter_periods"
                              v-model:field="premium_quarter" />

                <FormDropdown fieldName="premium_month"
                              label="Month"
                              size="5"
                              :filtering="false"
                              :options="select?.month_periods"
                              v-model:field="premium_month" />
            </div>
            <div class="grid">
                <Button :label="__('Set')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="setPremiumPeriod"></Button>
                <Button :label="__('Delete')"
                        icon="pi pi-trash"
                        class="p-button-danger"
                        style="margin:10px 5px;"
                        @click="removePremiumPeriod"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="cancelModalDialog">
                </Button>
            </div>
        </v-form>
        <template #footer>
            <span v-html="formFooter"></span>
        </template>
    </Dialog>

</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import { useForm, router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { __, setStoreValue, removeStoreValue, getFromRoute, setSessionValue, removeSessionValue, setServerValue, getServerValue, removeServerValue } from "@pages/Helper/utils.js";
import { prepareSelect } from "@pages/Helper/crud_utils.js";
import { formatPremiumPeriod } from '@pages/Helper/custom.js'

const props = defineProps({
    premium_year: [String, Number],
    premium_quarter: [String, Number],
    premium_month: [String, Number],
    currentModule: String,
})

const premium_year = ref(props.premium_year)
const premium_quarter = ref(props.premium_quarter)
const premium_month = ref(props.premium_month)

const isVisibleDialog = ref(true)

const formHeader = ref("<span class='setting-period-header'>" + __('Set premium period') + "</span>")
const formFooter = ref("<span class='setting-period-footer'>" + __('Set period form quarter and/or month premium groups') + "</span>")

const options = ["quarter_periods", "month_periods"];
const select = ref(null);

setStoreValue('currentModule', props.currentModule)

// -----------------------------------------------------

onBeforeMount(async () => {
    select.value = await prepareSelect(options)
    setStoreValue('premiumPeriod', { 'premium_year': props.premium_year, 'premium_quarter': props.premium_quarter, 'premium_month': props.premium_month })
})

// ---------------------------------------------------------------------------------------------

const hideDialog = async () => {
    router.visit(route('worker-index'))
}
// ---------------------------------------------------------------------------------------------

const autoSaveDialog = () => {
    setPremiumPeriod()
}

// ---------------------------------------------------------------------------------------------

const setPremiumPeriod = async () => {
    isVisibleDialog.value = false
    const formData = {
        premium_year: premium_year.value,
        premium_quarter: premium_quarter.value,
        premium_month: premium_month.value,
    }

    const response = await getFromRoute('set-premium-period', formData)

    setStoreValue('premiumPeriod', { 'premium_year': response.premium.year, 'premium_quarter': response.premium.quarter, 'premium_month': response.premium.month })
    //await setServerValue('premiumPeriod', { 'premium_year': response.premium.year, 'premium_quarter': response.premium.quarter, 'premium_month': response.premium.month })
}

// ---------------------------------------------------------------------------------------------

const removePremiumPeriod = async () => {
    isVisibleDialog.value = false
    await getFromRoute('remove-premium-period')

    removeStoreValue('premiumPeriod')
    //await removeServerValue('premiumPeriod')
}

// ---------------------------------------------------------------------------------------------

const cancelModalDialog = () => {
    isVisibleDialog.value = false
}

// ---------------------------------------------------------------------------------------------

</script>

<style scoped></style>
