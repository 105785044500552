<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Dropdown optionLabel="label"
                  optionValue="value"
                  ref="dropdownRef"
                  :filter="filtering"
                  class="w-full"
                  :scrollHeight="scrollHeight"
                  :class="{ 'required-field': hasRequiredAttr }"
                  :showClear="showClear"
                  :name="fieldName"
                  :validation="validation"
                  :virtualScrollerOptions="{ itemSize: itemSize }"
                  :options="options"
                  :disabled="isFormDisabled"
                  @change="change"
                  @filter="filter"
                  v-model="field"
                  :pt="{
        item: {
            style: optionStyle,
            class: optionClass,
        },
        input: {
            style: inputStyle ?? optionStyle,
            class: inputClass ?? optionClass,
        }
    }" />
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import { __, translatePrimeVueMsgs } from '@pages/Helper/utils.js'

translatePrimeVueMsgs()

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
        default: [],
        required: true
    },
    change: {
        type: Function,
        required: false
    },
    filter: {
        type: Function,
        required: false,
    },
    filtering: {
        type: Boolean,
        default: true
    },
    showClear: {
        type: Boolean,
        default: false
    },
    scrollHeight: {
        type: String,
        default: '200px'
    },
    setFirst: {
        type: Boolean,
        default: false
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    optionClass: {
        type: String,
        default: null
    },
    optionStyle: {
        type: [Object, String],
        default: null
    },
    inputClass: {
        type: [Object, String],
        default: null
    },
    inputStyle: {
        type: [Object, String],
        default: null
    },

    inRepeater: {
        type: Boolean,
        default: false
    },
    itemSize: {
        type: Number,
        default: 38
    },
    default: {
        type: [String, Number],
        default: null
    }
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

const dropdownRef = ref(null);

// ------------------------------------------------------------------------------

watch(() => ({ field: field.value, options: props.options, default: props.default }), (newValue, oldValue) => {
    if (props.setFirst && (field.value === null || field.value === undefined) && newValue?.options?.length > 0) {
        field.value = newValue.options[0].value;
    }
    if (props.default && (field.value === null || field.value === undefined)) {
        field.value = props.default;
    }
}, { immediate: true });

// ------------------------------------------------------------------------------

onMounted(async () => {
    if (props.isFocus) {
        await nextTick()
        dropdownRef.value.$el.children[0]?.focus();
    }
});

// ------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
