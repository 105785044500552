<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formSetting"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:60%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Report selection')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="report_id"
                                      label="Select report"
                                      showClear
                                      :filtering="false"
                                      :isFormDisabled="false"
                                      :options="select?.reports"
                                      v-model:field="report.report_id" />
                    </div>
                    <div class="grid">
                        <FormInputToggleButton fieldName="is_send_via_email"
                                               label="Send report by email?"
                                               size="2"
                                               :isFormDisabled="false"
                                               v-model:field="report.is_send_via_email" />
                        <FormInputText v-if="report.is_send_via_email"
                                       fieldName="emails"
                                       label="Email addresses (separated by comma)"
                                       size="10"
                                       :validation="['email']"
                                       :isFormDisabled="false"
                                       v-model:field="report.emails" />

                        <!-- <FormDropdown fieldName="format"
                                      label="Data format"
                                      showClear
                                      default="xlsx"
                                      size="2"
                                      :filtering="false"
                                      :isFormDisabled="false"
                                      :options="select?.reportformats"
                                      v-model:field="report.format" /> -->
                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Next')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="buildConditions"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="cancelOperation">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormDropdown from "@crud/FormDropdown.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import FormInputText from "@crud/FormInputText.vue";
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { __, makeReport } from '@pages/Helper/utils.js'
import { prepareSelect } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const isLoading = ref(true);
const options = ["reports", "reportformats"];
const select = ref(null);

const report = ref(props.data)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(options, null, report)
    report.value.format = 'xlsx';
    isLoading.value = false;
});

// -----------------------------------------------------

const buildConditions = async () => {
    let isConditions = true;
    select.value.reports.forEach((item) => {
        if (item?.value == report.value.report_id) {
            isConditions = item?.is_conditions
        }
    })

    if (!isConditions) {
        makeReport(props.data)
        return;
    }

    router.post(route('report-conditions'), { ...report.value })
}

// -----------------------------------------------------

const cancelOperation = () => {
    router.get(route('worker-index'));
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
