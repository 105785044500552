<template>
    <v-form :name="searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="user"
                                   label="User"
                                   size="4"
                                   v-model:field="search.user" />

                    <FormCalendar fieldName="date_from"
                                  label="Date from"
                                  size="4"
                                  v-model:field="search.created_at_from" />

                    <FormCalendar fieldName="date_to"
                                  label="Date to"
                                  size="4"
                                  v-model:field="search.created_at_to" />

                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)

let search = ref({})
prepareSearch(search, props)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
