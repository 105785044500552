import { ZiggyVue } from "ziggy";
import LaravelPermissionToVueJS from "../../vendor/zodexnl/spatie-permission-to-vue-inertia/src/js";
import { createApp, h, nextTick } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { usePage } from '@inertiajs/vue3'
import LayoutVerticalMenu from "./Pages/Layouts/LayoutVerticalMenu.vue";
import LayoutHorizontalMenu from "./Pages/Layouts/LayoutHorizontalMenu.vue";
import FlashMessage from "./Pages/Helper/FlashMessage.vue";
import VColCustom from "./Pages/Layouts/VCol.vue";
import VRowCustom from "./Pages/Layouts/VRow.vue";
import VFormCustom from "./Pages/Crud/VForm.vue";
import VViewCustom from "./Pages/Crud/VView.vue";

import "@fortawesome/fontawesome-free/css/all.css";


import "primeicons/primeicons.css";

import "primeflex/primeflex.css";

import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
//import "primevue/resources/themes/lara-light-green/theme.css"

import "../css/colors.css"
import "../css/images.css"
import "../css/auth.css";
import "../css/app.css";
import "../css/menu.css";
import "../css/view.css";
import "../css/form.css";
import "../css/helper.css";

import "../css/spinner.css";

import { createVuetify } from "vuetify";
import { VLayout } from "vuetify/components/VLayout";
import { VAppBar } from "vuetify/components/VAppBar";
import { VAppBarTitle } from "vuetify/components/VAppBar";
import { VApp } from "vuetify/components/VApp";
import { VImg } from "vuetify/components/VImg";
import { VList, VListItem } from "vuetify/components/VList";
import { VDivider } from "vuetify/components/VDivider";
import { VNavigationDrawer } from "vuetify/components/VNavigationDrawer";
import { VFooter } from "vuetify/components/VFooter";
import { VMain } from "vuetify/components/VMain";
import { VRow, VCol } from "vuetify/components/VGrid";

import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';

import ToastService from 'primevue/toastservice';
import Ripple from 'primevue/ripple';

import { createPinia } from "pinia";

import { everyTimeCalledFunctions, onlyOnceCalledFunctions } from "./init.js";
import { getSessionValue } from "@helper/utils.js";

const initApp = {
    install(app) {
        onlyOnceCalledFunctions();
    },
};

const pinia = createPinia();

const vuetify = createVuetify({
    components: {
        VLayout,
        VNavigationDrawer,
        VFooter,
        VMain,
        VRow,
        VCol,
        VAppBar,
        VAppBarTitle,
        VApp,
        VDivider,
        VList,
        VListItem,
        VImg,
    },
    directives: {
    },
    defaultTheme: 'light',
});

createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 250,

        // The color of the progress bar...
        color: "red",

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: true,
    },

    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        const page = pages[`./Pages/${name}.vue`];
        const menuOrientation = getSessionValue("user.menuOrientation") ?? 'H'
        const layout = menuOrientation == "V" ? LayoutVerticalMenu : LayoutHorizontalMenu;

        page.default.layout = page.default.layout || layout //LayoutHorizontalMenu; //LayoutVerticalMenu;
        return page;
    },

    title: (title) => {
        const page = usePage();
        return title ? title : page?.props?.appCaption;
    },

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        if (process.env.NODE_ENV === 'production') {
            app.config.devtools = false;
            app.config.productionTip = false;
        }

        app.mixin({
            created: everyTimeCalledFunctions,
            // methods: {
            //   can: LaravelPermissionToVueJS.can,
            // }
        });

        app.directive('focus', {
            mounted(el, binding) {
                nextTick(() => {
                    if (binding.value) {
                        el.focus();
                    }
                });
            },
        });
        app.directive('tooltip', Tooltip);
        app.directive('ripple', Ripple);

        app.use(pinia)
            .use(initApp)
            .use(plugin)
            .use(ZiggyVue)
            .use(LaravelPermissionToVueJS)
            .use(vuetify)
            .use(PrimeVue, { ripple: true })
            .use(ToastService)
            .component("flash-message", FlashMessage)
            .component("v-col", VColCustom)
            .component("v-row", VRowCustom)
            .component("v-form", VFormCustom)
            .component("v-view", VViewCustom)
            .mount(el);
    },
});
