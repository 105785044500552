import { ref } from "vue";
import { defineStore } from "pinia";
import { getAppName, getAppCaption, getLocale, getCurrentDate } from "@helper/utils.js";

export const useAppStore = defineStore("app", {
    state: () => ({
        appName: getAppName(),
        appCaption: getAppCaption(),
        locale: getLocale(),
        permissions: {},
        roles: {},
        superAdminRole: null,
        currentModule: null,
        premiumPeriod: null,
        deputyName: null,
        currentDate: null,
    }),
    getters: {
        getAppName: (state) => state.appName,
        getAppCaption: (state) => state.appCaption,
        getLocale: (state) => state.locale,
        getPermissions: (state) => state.permissions,
        getRoles: (state) => state.roles,
        getSuperAdminRole: (state) => state.superAdminRole,
        // getPremiumPeriod: (state) => state.premiumPeriod,
    },
    actions: {
        setAppName(value) {
            this.appName = value;
        },
        setAppCaption(value) {
            this.appCaption = value;
        },
        setLocale(value) {
            this.locale = value;
        },
    },
});
