<template>
    <v-form name="sandboxForm">
        <ConfirmDialog header="Confirm operation"
                       question="Do you want to run sandbox code?"
                       v-model="isConfirmationOpen"
                       :operation="runSandboxCode" />

        <div class="grid">
            <Button @click.stop="openConfirmationWindow()"
                    style="background-color:cadetblue;margin:0 7px 0 25px"
                    severity="info">
                <i class="fa-solid fa-unlock-keyhole"
                   style="margin-right:5px;font-size:18px"></i>{{ __('Uruchom kod Sandbox') }}</Button>

        </div>
    </v-form>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Button from 'primevue/button'
import { __, getFromRoute } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})
const isConfirmationOpen = ref(false)

// -----------------------------------------------------

onMounted(async () => {

})

// -----------------------------------------------------

const openConfirmationWindow = () => {
    isConfirmationOpen.value = true
}

// -----------------------------------------------------

const runSandboxCode = async () => {
    try {
        const returnData = await getFromRoute('sandbox-run', {})
        console.log(returnData)
    } catch (e) {
        console.err(e)
    }
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
