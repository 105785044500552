<template>
    <v-form>
        <div class="grid">
            <FormFileUploader fieldName="files"
                              :label="__('Data file')"
                              labelStyle="font-weight:bold"
                              maxFiles="1"
                              category="import"
                              :allowedExtensions="['xlsx']"
                              v-model:field="fileToImport" />
        </div>

        <div class="grid">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="makeImport">
                <i class="fa-solid fa-file-arrow-up"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Make import') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancelImport">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import FormFileUploader from '@pages/Crud/FormFileUploader.vue'
import Button from 'primevue/button';
import { __ } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    afterImportFunction: Function,
    importRoute: String,
})

const fileToImport = ref([])
const isImportModalOpen = defineModel('isImportModalOpen')

// -----------------------------------------------------

const cancelImport = () => {
    isImportModalOpen.value = false
}
// -----------------------------------------------------

const makeImport = () => {
    useForm({ fileToImport: fileToImport.value.import }).post(route(props.importRoute), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.log(errors)
        },
        onFinish: () => {
            props.afterImportFunction()
        }
    })

    isImportModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
