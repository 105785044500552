<template>
    <div class="chart-container"
         :style="{ width, height }">
        <canvas ref="chart"></canvas>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { Chart, registerables } from 'chart.js';
import { screenMessage } from '@pages/Helper/utils.js';

const props = defineProps({
    chartData: Object,
})

Chart.register(...registerables);

const chart = ref(null);
const width = ref('80vw');
const height = ref('70vh');
console.log(props)
onMounted(() => {
    const ctx = chart.value.getContext('2d');

    if (props.chartData?.width) {
        width.value = props.chartData.width;
    }
    if (props.chartData?.height) {
        height.value = props.chartData.height;
    }

    if (props.chartData && props.chartData.data && props.chartData.type) {
        const type = JSON.parse(JSON.stringify(props.chartData.type));
        const data = JSON.parse(JSON.stringify(props.chartData.data));
        const options = JSON.parse(JSON.stringify(props.chartData.options || {}));

        new Chart(ctx, { type: type, data: data, options: options });
    } else {
        const message = { message: ("Unproper data to create chart"), title: ("Cannot create chart"), type: "error", position: "center", timeout: 5 };
        screenMessage(message);

        router.post(route('chart-index'))
    }
});

// ----------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .chart-container {
        //width: 80vw;
        //height: 70vh;
        position: relative;
    }
</style>
