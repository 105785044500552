<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="w-full sheet-section-title">{{ __('Rejected reason') }}</div>
                <div class="grid sheet-box-divider">
                    <FormTextarea fieldName="rejected_reason"
                                  label="Reason for lack of acceptance"
                                  :rows="4"
                                  :cols="30"
                                  :isFormDisabled="true"
                                  v-model:field="crud.rejected_reason" />
                </div>

                <div class="w-full sheet-section-title">{{ __('Sheet data') }}</div>
                <div class="grid sheet-box-divider">
                    <FormInputText v-if="!props.formProps.addMode"
                                   fieldName="sheet_number"
                                   label="Sheet number"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.sheet_number_preview" />
                    <FormInputText v-else
                                   fieldName="sheet_number"
                                   label="Sheet number"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.sheet_number" />
                    <FormDropdown v-if="!formProps.addMode"
                                  fieldName="status_id"
                                  label="Status"
                                  size="2"
                                  :isFormDisabled="true"
                                  :options="select?.statuses"
                                  v-model:field="crud.status_id" />
                    <FormCalendar fieldName="sheet_date"
                                  label="Sheet creation date"
                                  size="2"
                                  :isFormDisabled="true"
                                  v-model:field="crud.sheet_date" />
                    <FormDropdown fieldName="worker_id"
                                  label="Worker"
                                  size="6"
                                  :isFormDisabled="true"
                                  :options="select?.workers"
                                  v-model:field="crud.worker_id" />
                    <FormDropdown fieldName="position_id"
                                  label="Position"
                                  size="4"
                                  :isFormDisabled="true"
                                  :options="select?.positions"
                                  v-model:field="crud.position_id" />
                    <FormDropdown fieldName="dept_id"
                                  label="Department"
                                  size="4"
                                  :isFormDisabled="true"
                                  :options="select?.depts"
                                  v-model:field="crud.dept_id" />
                    <FormInputText fieldName="premium_group"
                                   label="Premium group"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.premium_symbol_sheet" />
                    <FormInputText fieldName="assessment_period"
                                   label="Assessment period"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.assessment_period" />
                </div>

                <span v-if="premium.is_premium_right">
                    <div class="w-full sheet-section-title">{{ __('Premium right') }}</div>
                    <div class="grid sheet-box-divider">
                        <FormInputToggleButton fieldName="is_premium_right"
                                               label="Premium right"
                                               size="12"
                                               :isFormDisabled="true"
                                               v-model:field="crud.is_premium_right" />
                    </div>
                    <div v-if="!crud.is_premium_right">
                        <FormTextarea fieldName="no_premium_right_reason"
                                      label="Reason for lack of premium right"
                                      validation="required"
                                      :rows="4"
                                      :cols="30"
                                      :isFormDisabled="true"
                                      v-model:field="crud.no_premium_right_reason" />
                    </div>
                </span>

                <div class="w-full sheet-section-title">{{ __('Assessment') }}</div>
                <div class="grid sheet-box-divider">
                    <div class="sheet-total-result">{{ crud.assessment }}%</div>
                </div>

            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :buttonsVisibility="buttonsVisibility">
            </FormButtons>
        </div>

    </v-form>
</template>

<script setup>
import { ref, onMounted } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { initForm } from "@pages/Helper/crud_utils.js";
import FormInputText from "@crud/FormInputText.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormCalendar from "@crud/FormCalendar.vue";
import FormTextarea from "@crud/FormTextarea.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import FormButtons from '@crud/FormButtons.vue'
import { __ } from '@helper/utils.js'

// --------------------------------------------------------------------------------------------------------------------------------------------

const props = defineProps({
    formProps: Object,
});

const isLoading = ref(true);
const isFormDisabled = ref(false);

const options = ["workers", "positions", "depts", "hrevents", "statuses"];
const select = ref(null);
const crud = ref({});
const premium = ref({});
const buttonsVisibility = ref(null);

// --------------------------------------------------------------------------------------------------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility);
    premium.value = crud.value.premiumGroupDefinition;
});

// --------------------------------------------------------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
