<template>
    <ConfirmDialog header="Confirm delete"
                   question="Do you want to delete selected record?"
                   v-model="isConfirmationOpen"
                   :operation="deleteData" />

    <div style="margin-top:20px;text-align:right;">
        <hr style="margin-bottom:20px">
        <div v-if="editMode || addMode">
            <slot v-if="addMode"
                  name="before-in-add"
                  :saveData="saveData"
                  :closeForm="closeForm"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <slot v-if="editMode"
                  name="before-in-edit"
                  :saveData="saveData"
                  :deleteData="deleteData"
                  :closeForm="closeForm"
                  :deleteWithConfirmation="openConfirmationWindow"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <slot v-if="editMode || addMode"
                  name="before-in-add-edit"
                  :saveData="saveData"
                  :deleteData="deleteData"
                  :closeForm="closeForm"
                  :deleteWithConfirmation="openConfirmationWindow"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <!-- <Button v-if="addMode || !buttonsVisibility?.deny?.includes('save') && (buttonsVisibility?.allow?.includes('save') || unprotected.includes(formName, '.save') || hasPermission([formName, 'save']))" -->
            <Button @click="() => saveData(showAfterSave)"
                    :disabled="sourceForm.processing"
                    style="margin:0 5px"
                    severity="success">
                {{ __('Save') }}
            </Button>
            <Button v-if="editMode && !buttonsVisibility?.deny?.includes('delete') && (buttonsVisibility?.allow?.includes('delete') || unprotected.includes(formName + '.delete') || hasPermission([formName, 'delete']))"
                    @click="openConfirmationWindow"
                    :disabled="sourceForm.processing"
                    style="margin:0 5px"
                    severity="danger">
                {{ __('Delete') }}
            </Button>

            <Button @click="cancelEdit"
                    :disabled="sourceForm.processing"
                    style="margin:0 5px"
                    severity="secondary">
                {{ __('Cancel') }}
            </Button>
            <slot v-if="addMode"
                  name="after-in-add"
                  :saveData="saveData"
                  :closeForm="closeForm"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <slot v-if="editMode"
                  name="after-in-edit"
                  :saveData="saveData"
                  :deleteData="deleteData"
                  :closeForm="closeForm"
                  :deleteWithConfirmation="openConfirmationWindow"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <slot v-if="editMode || addMode"
                  name="after-in-add-edit"
                  :saveData="saveData"
                  :deleteData="deleteData"
                  :closeForm="closeForm"
                  :deleteWithConfirmation="openConfirmationWindow"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
        </div>
        <div v-else>
            <slot name="before-in-show"
                  :editData="editData"
                  :closeForm="closeForm"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
            <Button v-if="!buttonsVisibility?.deny?.includes('edit') && (buttonsVisibility?.allow?.includes('edit') || unprotected.includes(formName, '.edit') || hasPermission([formName, 'edit']))"
                    @click="editData"
                    :disabled="sourceForm.processing"
                    style="margin:0 5px"
                    severity="warning">
                {{ __('Edit') }}
            </Button>
            <Button @click="closeForm"
                    :disabled="sourceForm.processing"
                    style="margin:0 5px"
                    severity="secondary">
                {{ __('Back') }}
            </Button>
            <slot name="after-in-show"
                  :editData="editData"
                  :closeForm="closeForm"
                  :sourceForm="sourceForm"
                  :formProps="formProps"></slot>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useForm, router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import { setFormState, validMsgHeaderRemove, validMsgFieldRemove, validMsgHeaderAdd, validMsgFieldAdd, onErrorService } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, hasPermission, convertDate } from '@pages/Helper/utils.js'

const props = defineProps({
    formProps: Object,
    sourceForm: Object,
    uploadedFiles: Object,
    buttonsVisibility: {
        type: Object,
        default: () => ({
            'allow': [],
            'deny': [],
        }),
    },
    showAfterSave: { type: Boolean, default: false },
    beforeSave: { type: Function, default: null },
    validation: { type: Function, default: null }
})

const formName = props.formProps.formName;
const unprotected = props.formProps.unprotected ?? [];
const isFormDisabled = defineModel('isFormDisabled')
const autoSave = defineModel('autoSave')
const isConfirmationOpen = ref(false)

const editMode = ref(props.formProps.editMode)
const addMode = ref(props.formProps.addMode)

// -----------------------------------------------------------------------------------------------------------

setFormState(isFormDisabled, editMode, addMode)

// -----------------------------------------------------------------------------------------------------------

watch(() => autoSave.value, (newValue, oldValue) => {
    if (newValue) {
        saveData()
    }
}, { deep: true, immediate: true })

// -----------------------------------------------------------------------------------------------------------

const saveData = async (showAfterSave = false) => {
    const routeName = editMode.value ? props.formProps.routes.update : props.formProps.routes.store

    let sourceForm = prepareFiles(props.sourceForm)

    for (let field in sourceForm) {
        if (sourceForm[field] instanceof Date) {
            sourceForm[field] = convertDate(sourceForm[field])
        }
        if (field === 'log' && Array.isArray(sourceForm[field])) {
            delete sourceForm[field]
        }
        if (field === 'parent_id' && typeof sourceForm[field] === 'object' && checkTreeObject(sourceForm[field])) {
            sourceForm[field] = parseInt(Object.keys(sourceForm[field])[0])
        }
    }

    if (props.beforeSave) {
        sourceForm = await props.beforeSave(sourceForm)
        if (sourceForm === false) {
            return false;
        }
    }

    useForm(sourceForm).post(route(routeName), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onBefore: (visit) => {
            return onBeforeService(visit, sourceForm)
        },
        onError: (errors) => {
            onErrorService(errors)
        },
        onSuccess: () => {
            if (showAfterSave) {
                cancelEditAndShow()
            } else {
                cancelEdit()
            }

        },
        onFinish: () => {
            props.formProps.getLazyData()
        }
    })
}

// -----------------------------------------------------------------------------------------------------------

const checkTreeObject = (obj) => {
    if (obj === undefined || obj === null) {
        return false;
    }

    const keys = Object.keys(obj);

    if (keys.length !== 1) {
        return false;
    }

    const key = keys[0];
    const value = obj[key];

    return !isNaN(key) && typeof value === 'boolean';
}

// -----------------------------------------------------------------------------------------------------------

const onBeforeService = (visit, sourceForm) => {
    Object.keys(visit.data).forEach(key => {
        if (visit.data[key] === null && addMode.value === true) {
            delete visit.data[key];
        }
    });

    if (props.validation) {
        return props.validation(sourceForm, visit)
    }

    return true
}

// -----------------------------------------------------------------------------------------------------------

const prepareFiles = (sourceForm) => {
    sourceForm.files = { ...sourceForm.files ?? [] }
    for (let key in sourceForm) {
        if (Array.isArray(sourceForm[key])) {
            sourceForm[key].forEach(elem => {
                if (elem?.files) {
                    elem.files = { ...elem.files ?? [] }
                }
            })
        }
    }
    return sourceForm
}

// -----------------------------------------------------------------------------------------------------------

const deleteData = () => {
    router.post(route(props.formProps.routes.delete), { 'selectedToDelete': [props.sourceForm?.id] }, {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            console.log(errors)
        },
        onFinish: () => {
            props.formProps.getLazyData()
            cancelEdit()
        }
    })
}

// -----------------------------------------------------------------------------------------------------------

const editData = () => {
    editMode.value = true
    setFormState(isFormDisabled, editMode, addMode)
}

// -----------------------------------------------------------------------------------------------------------

const openConfirmationWindow = () => {
    isConfirmationOpen.value = true
}

// -----------------------------------------------------------------------------------------------------------

const cancelEdit = () => {
    //screenMessage()
    if (editMode.value || addMode.value) {
        closeForm()
    }
}

// -----------------------------------------------------------------------------------------------------------

const cancelEditAndShow = () => {
    //screenMessage()
    editMode.value = false;
    addMode.value = false
}

// -----------------------------------------------------------------------------------------------------------

const closeForm = () => {
    props.formProps.closeModal()
}

// -----------------------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .error-message-header {
        color: red !important
    }
</style>
