<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formSetting"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:60%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="chart_id"
                                      label="Select chart"
                                      showClear
                                      :filtering="false"
                                      :isFormDisabled="false"
                                      :options="select?.charts"
                                      v-model:field="chart.chart_id" />
                    </div>
                    <div class="grid">
                        <FormDropdown fieldName="chartType"
                                      label="Chart type"
                                      showClear
                                      default="bar"
                                      size="2"
                                      :filtering="false"
                                      :isFormDisabled="false"
                                      :options="select?.charttypes"
                                      v-model:field="chart.type" />
                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Next')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="buildConditions"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="router.get(route('worker-index'))">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormDropdown from "@crud/FormDropdown.vue";
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { __, makeChart, screenMessage } from '@pages/Helper/utils.js'
import { prepareSelect } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const isLoading = ref(true);
const options = ["charts", "charttypes"];
const select = ref(null);

const chart = ref(props.data)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(options, null, chart)
    isLoading.value = false;
});

// -----------------------------------------------------

const buildConditions = async () => {
    let isConditions = true;
    select.value.charts.forEach((item) => {
        if (item?.value == chart.value.chart_id) {
            isConditions = item?.is_conditions
        }
    })

    if (!isConditions) {
        makeChart(props.data)
        return;
    }

    router.post(route('chart-conditions'), { ...chart.value })
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
