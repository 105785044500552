import { getStoreValue, getServerValue } from '@pages/Helper/utils.js';

//----------------------------------------------------------------------

export const formatPremiumPeriod = (premiumObj) => {
    let period = '';

    if (premiumObj?.hasOwnProperty('premium_year')) {
        period += 'rok: ' + premiumObj?.premium_year.toString() + ', ';
    }
    if (premiumObj?.hasOwnProperty('premium_quarter')) {
        period += 'kwartał: ' + premiumObj?.premium_quarter?.toString().substring(2) + ', ';
    }
    if (premiumObj?.hasOwnProperty('premium_month')) {
        period += 'miesiąc: ' + premiumObj?.premium_month?.toString().substring(1);
    }

    return period;
}

//----------------------------------------------------------------------

export const getSessionPeriod = async () => {
    let premiumPeriod = getStoreValue('premiumPeriod');
    if (!premiumPeriod) {
        let savedPremiumPeriod = await getServerValue('premiumPeriod')
        if (savedPremiumPeriod?.hasOwnProperty('premiumPeriod')) {
            premiumPeriod = savedPremiumPeriod.premiumPeriod
        }
    }

    if (premiumPeriod) {
        return { 'year': period.premium_year, 'quarter': period.premium_quarter, 'month': period.premium_month };
    } else {
        return null
    }
}
//----------------------------------------------------------------------
