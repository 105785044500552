<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <div v-for="element in data"
             :key="element.value"
             class="flex align-items-center"
             style="margin-top: 5px">
            <RadioButton :disabled="isFormDisabled"
                         :name="fieldName"
                         :value="element.value"
                         @change="change"
                         v-focus="isFocus"
                         v-model="field" />
            <label class="ml-2"
                   :for="element"
                   :class="elementClass"
                   :style="elementStyle">{{ element.label }}</label>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import RadioButton from 'primevue/radiobutton';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    data: {
        type: Array,
        default: []
    },
    change: {
        type: Function,
        required: false
    },
    elementClass: {
        type: String,
        default: null
    },
    elementStyle: {
        type: [Object, String],
        default: null
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    default: {
        type: [String, Number],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

//------------------------------------------------------------------------------

watch(() => field.value, () => {
    if (props.default !== null && (field.value === null || field.value === undefined || field.value === '')) {
        field.value = props.default;
    }
}, { immediate: true });

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
