<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputNumber fieldName="current_year"
                                     label="Year"
                                     size="2"
                                     :default="null"
                                     :useGrouping="false"
                                     :isFocus="true"
                                     v-model:field="search.current_year" />

                    <FormDropdown fieldName="current_period"
                                  label="Assessment period"
                                  showClear
                                  size="3"
                                  :filtering="false"
                                  :options="select?.premium_periods"
                                  v-model:field="search.current_period" />

                    <FormMultiselect fieldName="worker_id"
                                     label="Workers"
                                     size="4"
                                     showClear
                                     :options="select?.workers"
                                     :filter="(event) => onFilter(event, 'workers')"
                                     v-model:field="search.worker_id" />

                    <FormMultiselect fieldName="status_id"
                                     label="Status"
                                     size="3"
                                     showClear
                                     :options="select?.statuses"
                                     :filter="(event) => onFilter(event, 'statuses')"
                                     v-model:field="search.status_id" />

                    <FormMultiselect fieldName="manager_id"
                                     label="Managers"
                                     size="4"
                                     showClear
                                     :options="select?.realmanagers"
                                     :filter="(event) => onFilter(event, 'realmanagers')"
                                     v-model:field="search.manager_id" />

                    <FormMultiselect fieldName="dept_id"
                                     label="Departments"
                                     size="4"
                                     showClear
                                     :options="select?.depts"
                                     :filter="(event) => onFilter(event, 'depts')"
                                     v-model:field="search.dept_id" />

                    <FormMultiselect fieldName="premium_id"
                                     label="Premium group"
                                     size="4"
                                     showClear
                                     :options="select?.premiums"
                                     v-model:field="search.premium_id" />

                    <FormCalendar fieldName="sheet_date_from"
                                  label="Sheet date from"
                                  size="2"
                                  :isFocus="true"
                                  v-model:field="search.sheet_date_from" />
                    <FormCalendar fieldName="sheet_date_to"
                                  label="Sheet date to"
                                  size="2"
                                  v-model:field="search.sheet_date_to" />

                    <FormCalendar fieldName="assessment_date_from"
                                  label="Assessment date from"
                                  size="2"
                                  :isFocus="true"
                                  v-model:field="search.assessment_date_from" />
                    <FormCalendar fieldName="assessment_date_to"
                                  label="Assessment date to"
                                  size="2"
                                  v-model:field="search.assessment_date_to" />

                    <FormCalendar fieldName="approved_date_from"
                                  label="Approved date from"
                                  size="2"
                                  :isFocus="true"
                                  v-model:field="search.approved_date_from" />
                    <FormCalendar fieldName="approved_date_to"
                                  label="Approved date to"
                                  size="2"
                                  v-model:field="search.approved_date_to" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormInputNumber from "@crud/FormInputNumber.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormMultiselect from "@crud/FormMultiselect.vue";
import FormCalendar from "@crud/FormCalendar.vue";
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch, onFilterService } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const isLoading = ref(true);
const options = ["workers", "statuses", "realmanagers", "depts", "premiums", "premium_periods"];
const select = ref(null);

let search = ref({})

prepareSearch(search, props, options, select)

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, search, null);
}

// -----------------------------------------------------
</script>

<style lang="scss"
       scoped></style>
