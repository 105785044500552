<template>
    <div
        style="display:flex;flex-direction:column;align-items:flex-start;"
        :style="repeaterStyle"
    >
        <ConfirmDialog
            header="Confirm delete"
            question="Do you want to choose to delete selected row?"
            v-model="isConfirmationOpen"
            :operation="deleteRow"
            :parameter="recordSelectedToDelete"
        />

        <Button
            @click="addRow"
            :disabled="disabled"
            style="margin-left:3px;"
            label="Dodaj"
        />
        <!-- <div
            class="grid"
            style="display:flex; overflow-x: auto;overflow-y:hidden;padding-left:3px"
        > -->
        <table>
            <!-- <tr> -->
            <tr>
                <th
                    v-for="(label, index) in title"
                    :key="index"
                >{{ label }}</th>
                <th>X</th>
            </tr>
            <tr
                v-for="(record, index) in currentRecord"
                :key="record?.id ?? index"
                :name="'repeater-' + index + '-' + name"
                :forceLoop="forceLoop"
            >

                <slot
                    name="repeat"
                    :record="record"
                    :index="renameElements(index)"
                />


                <td
                    v-if="!disabled"
                    @click.stop="openConfirmationWindow(record)"
                    style="top:25px;font-weight:900;color:red;cursor:pointer;font-size:20px"
                >
                    <span>
                        <i
                            v-tooltip.top="'Usuń' + (record?.id ? ' ' + record?.id : '')"
                            class="fa-solid fa-trash-can"
                        ></i>
                    </span>
                </td>
            </tr>
            <!-- </tr> -->
        </table>
        <!-- </div> -->
    </div>
</template>

<script setup>

import { ref, watch, nextTick } from 'vue';
import Button from 'primevue/button';
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import { __ } from '@pages/Helper/utils.js'


const props = defineProps({
    keys: Array,
    name: { type: String, required: true },
    isFirstRowEmpty: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    repeaterStyle: String,
    title: Array
})

const emit = defineEmits(['update:modelValue'])
const forceLoop = ref(false)
const isConfirmationOpen = ref(false)
const recordSelectedToDelete = ref(null)
//const title = ref(true)


let currentRecord = defineModel('record', { default: [] })

// ------------------------------------------------------------------------------

watch(() => currentRecord.value, (newValue, oldValue) => {
    if (currentRecord.value?.length == 0 && props.isFirstRowEmpty) {
        currentRecord.value = [{}]
    }

    forceLoop.value = !forceLoop.value
}, { deep: true, immediate: true })

// ------------------------------------------------------------------------------

const deleteRow = async (record) => {
    let newRecord = currentRecord.value.filter((elem) => {
        if (elem?.id && record?.id) {
            return elem.id !== record.id
        } else {
            return elem?.__index !== record?.__index
        }
    });
    currentRecord.value = newRecord
    await resetElements()
}

// ------------------------------------------------------------------------------

const addRow = () => {
    let keys = {}
    if (Array.isArray(props.keys)) {
        props.keys.forEach(elem => {
            if (currentRecord.value[0]?.hasOwnProperty(elem)) {
                keys[elem] = currentRecord.value[0][elem]
            } else {
                keys[elem] = null
            }
        })
    }
    currentRecord.value?.push({ id: null, __index: createRepeaterIndex(), files: [], ...keys });
}

// ------------------------------------------------------------------------------

const renameElements = async (index) => {
    await nextTick();
    let repeaterDiv = document?.querySelector("div[name='repeater-" + index + '-' + props.name + "']");
    let elementsWithName = repeaterDiv?.querySelectorAll('[name]');
    let currentName = null

    elementsWithName?.forEach(element => {
        currentName = element.getAttribute('name');
        if (!currentName.startsWith(props.name + '-')) {
            element.setAttribute('name', props.name + '-' + index + '-' + currentName);
            element.setAttribute('data-before', currentName)
        }
    });

    return index
}

// ------------------------------------------------------------------------------

const resetElements = async () => {
    await nextTick();
    let start = 'repeater-';
    let end = '-' + props.name;
    let repeaterDiv = document?.querySelector(`div[name^="${start}"][name$="${end}"]`);
    let elementsWithName = repeaterDiv?.querySelectorAll('[name]');

    elementsWithName?.forEach(element => {
        if (element.hasAttribute('data-before')) {
            element.setAttribute('name', element.getAttribute('data-before'));
            element.removeAttribute('data-before');
        }
    });
}

// ------------------------------------------------------------------------------

const createRepeaterIndex = () => {
    return Date.now().toString() + Math.random().toString();
}

// ------------------------------------------------------------------------------

const openConfirmationWindow = (record) => {
    recordSelectedToDelete.value = record
    isConfirmationOpen.value = true
}

//-----------------------------------------------------------------------------------------------

// const closeConfirmationWindow = () => {
//     isConfirmationOpen.value = false
//     recordSelectedToDelete.value = null
// }

//-----------------------------------------------------------------------------------------------

</script>

<style
    lang="scss"
    scoped
>
table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
}


/* Style table cells */
td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
</style>
