<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form :name="props.formProps.formName">
            <TabView scrollable
                     style="overflow: visible"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="w-full sheet-section-title">{{ __('Sheet data') }}</div>
                    <div class="grid sheet-box-divider">
                        <FormInputText v-if="!props.formProps.addMode"
                                       fieldName="sheet_number"
                                       label="Sheet number"
                                       size="2"
                                       :isFormDisabled="true"
                                       v-model:field="crud.sheet_number_preview" />
                        <FormInputText v-else
                                       fieldName="sheet_number"
                                       label="Sheet number"
                                       size="2"
                                       :isFormDisabled="true"
                                       v-model:field="crud.sheet_number" />
                        <FormDropdown v-if="!formProps.addMode"
                                      fieldName="status_id"
                                      label="Status"
                                      size="2"
                                      :isFormDisabled="true"
                                      :options="select?.statuses"
                                      v-model:field="crud.status_id" />
                        <FormCalendar fieldName="sheet_date"
                                      label="Sheet creation date"
                                      size="2"
                                      :isFormDisabled="true"
                                      v-model:field="crud.sheet_date" />
                        <FormDropdown fieldName="worker_id"
                                      label="Worker"
                                      size="6"
                                      :isFormDisabled="true"
                                      :options="select?.workers"
                                      v-model:field="crud.worker_id" />
                        <FormDropdown fieldName="position_id"
                                      label="Position"
                                      size="4"
                                      :isFormDisabled="true"
                                      :options="select?.positions"
                                      v-model:field="crud.position_id" />
                        <FormDropdown fieldName="dept_id"
                                      label="Department"
                                      size="4"
                                      :isFormDisabled="true"
                                      :options="select?.depts"
                                      v-model:field="crud.dept_id" />
                        <FormInputText fieldName="premium_group"
                                       label="Premium group"
                                       size="2"
                                       :isFormDisabled="true"
                                       v-model:field="premium.symbol" />
                        <FormInputText fieldName="assessment_period"
                                       label="Assessment period"
                                       size="2"
                                       :isFormDisabled="true"
                                       v-model:field="crud.assessment_period" />
                    </div>
                    <div class="grid grid-shadow">
                        <div class="w-full sheet-section-title"
                             style="margin-left:7px;">{{ __('Description') }}</div>
                        <FormTextarea fieldName="description"
                                      label=""
                                      :rows="4"
                                      :isFormDisabled="isFormDisabled"
                                      v-model:field="crud.description" />
                    </div>

                    <span v-if="premium.is_premium_right">
                        <div class="w-full sheet-section-title">{{ __('Premium right') }}</div>
                        <div class="grid sheet-box-divider">
                            <FormInputToggleButton fieldName="is_premium_right"
                                                   label="Worker meet expectations"
                                                   size="12"
                                                   :change="checkIsPremiumRight"
                                                   :isFormDisabled="isFormDisabled"
                                                   v-model:field="crud.is_premium_right" />
                        </div>
                        <div v-if="!crud.is_premium_right">
                            <FormTextarea fieldName="no_premium_right_reason"
                                          label="Reason for lack of premium right"
                                          validation="required"
                                          :rows="4"
                                          :cols="30"
                                          :isFormDisabled="isFormDisabled"
                                          v-model:field="crud.no_premium_right_reason" />
                        </div>
                    </span>

                    <div class="w-full sheet-section-title">{{ __('HR Events') }}</div>
                    <div class="grid sheet-box-divider">
                        <FormDropdown fieldName="event_id"
                                      label="HR Events"
                                      size="4"
                                      :filtering="false"
                                      :showClear="true"
                                      :change="(e) => getPercentValueOfHREvent(e.value)"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.hrevents"
                                      v-model:field="crud.event_id" />
                    </div>

                    <div v-if="crud.is_premium_right && (percentValueOfHREvent !== 0)">
                        <!-- Tabela: Kryteria jakościowe -->
                        <div class="w-full sheet-section-title">{{ __('Quality criterions') }}</div>
                        <div class="grid sheet-box-divider"
                             style="padding:0 10px 0 10px">
                            <FormRepeaterTable v-model:record="crud.sheetCriterions"
                                               :keys="{ 'premiumcriterion_id': null, 'weight': 0, 'assessment': 0, 'result': null, 'justification': null }"
                                               :disabled="isFormDisabled"
                                               :isFirstRowEmpty="false"
                                               :isCopyButton="false"
                                               :isDeleteButton="false"
                                               :isAddButton="false"
                                               cellStyle=""
                                               cellClass="sheet-cells"
                                               repeaterStyle="margin-top:5px;"
                                               name="sheetCriterions"
                                               :title="fromAssessmentStage ? [__('Criterium name'), __('Weight'), __('Assessment'), __('Result'), __('Justification')] : [__('Criterium name'), __('Weight')]">
                                <template #repeat="slotProps">

                                    <td :class="slotProps.cellClass">
                                        <FormDropdown :style="{ width: '180px' }"
                                                      fieldName="premiumcriterion_id"
                                                      optionClass="sheet-option-items"
                                                      :itemSize="20"
                                                      :isFormDisabled="true"
                                                      :inRepeater="true"
                                                      :filtering="false"
                                                      :options="select?.premiumcriterions"
                                                      v-model:field="slotProps.record.premiumcriterion_id" />
                                    </td>
                                    <td :class="slotProps.cellClass">
                                        <FormDropdown :style="{ width: '100px' }"
                                                      fieldName="weight"
                                                      optionClass="sheet-option-items"
                                                      class="weight-criterion"
                                                      :itemSize="20"
                                                      :isFormDisabled="isFormDisabled || !premium.is_weights_change || sheetFormButton == 'makeAssessment'"
                                                      :inRepeater="true"
                                                      :filtering="false"
                                                      :options="select?.weights"
                                                      :change="(e) => calcAssessmentResult(e, slotProps.record, 'criterion')"
                                                      v-model:field="slotProps.record.weight" />
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <FormDropdown :style="{ width: '200px' }"
                                                      fieldName="assessment"
                                                      optionClass="sheet-option-items"
                                                      :itemSize="20"
                                                      :isFormDisabled="isFormDisabled || sheetFormButton != 'makeAssessment'"
                                                      :inRepeater="true"
                                                      :filtering="false"
                                                      :options="select?.assessments"
                                                      :change="(e) => calcAssessmentResult(e, slotProps.record, 'criterion')"
                                                      v-model:field="slotProps.record.assessment" />
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <FormInputNumber :style="{ width: '80px' }"
                                                         fieldName="criterionResults"
                                                         suffix="%"
                                                         inputClass="sheet-fields"
                                                         :isFormDisabled="true"
                                                         :inRepeater="true"
                                                         v-model:field="criterionResults[slotProps.record.id]" />
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <FormTextarea :style="[{ width: '500px' }, sheetFormButton == 'makeAssessment' && slotProps.record.assessment == 0 ? requiredStyle : {}]"
                                                      fieldName="justification"
                                                      inputClass="sheet-fields"
                                                      :rows="2"
                                                      :cols="30"
                                                      :isFormDisabled="isFormDisabled || sheetFormButton != 'makeAssessment'"
                                                      :inRepeater="true"
                                                      v-model:field="slotProps.record.justification" />
                                    </td>
                                </template>
                                <template #footer="slotProps">
                                    <td :class="slotProps.cellClass"></td>
                                    <td :class="slotProps.cellClass"
                                        class="sheet-sum">{{ criterionsWeigthSum }}%</td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"></td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"
                                        class="sheet-sum">{{ criterionsResultSum }}%</td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"></td>
                                </template>
                            </FormRepeaterTable>
                        </div>
                        <!-- Koniec tabeli: Kryteria jakościowe -->
                        <!-- Tabela: Cele premiowe -->
                        <div v-if="premium.is_premium_targets"
                             class="w-full sheet-section-title">{{ __('Premium targets') }}</div>
                        <div v-if="premium.is_premium_targets"
                             class="grid sheet-box-divider"
                             style="padding:0 10px 0 10px">
                            <FormRepeaterTable v-model:record="crud.sheetTargets"
                                               :keys="{ 'type': null, 'target_name': 0, 'weight': 0, 'assessment': 0, 'result': null, 'justification': null, 'rate_value': null }"
                                               :disabled="isFormDisabled"
                                               :isFirstRowEmpty="false"
                                               :isCopyButton="false"
                                               :isDeleteButton="false"
                                               :isAddButton="false"
                                               cellStyle=""
                                               cellClass="sheet-cells"
                                               repeaterStyle="margin-top:5px;"
                                               name="sheetTargets"
                                               :title="fromAssessmentStage ? [__('Type'), __('Criterium name'), __('Weight'), __('Assessment'), __('Result'), __('Justification'), __('Rate value')] : [__('Type'), __('Criterium name'), __('Weight')]">
                                <template #repeat="slotProps">

                                    <td :class="slotProps.cellClass">
                                        <FormDropdown :style="{ width: '120px' }"
                                                      fieldName="type"
                                                      optionClass="sheet-option-items"
                                                      :itemSize="20"
                                                      :isFormDisabled="isFormDisabled || slotProps.record.premiumtask_id > 0 || sheetFormButton == 'makeAssessment'"
                                                      :inRepeater="true"
                                                      :filtering="false"
                                                      :options="select?.target_types"
                                                      :change="(e) => changeTargetType(e, slotProps.record)"
                                                      v-model:field="slotProps.record.type" />
                                    </td>

                                    <td v-if="slotProps.record.premiumtask_id"
                                        :class="slotProps.cellClass">
                                        <FormDropdown :style="{ width: '350px' }"
                                                      fieldName="premiumtask_id"
                                                      optionClass="sheet-option-items"
                                                      :itemSize="20"
                                                      :isFormDisabled="isFormDisabled || slotProps.record.premiumtask_id > 0 || sheetFormButton == 'makeAssessment'"
                                                      :inRepeater="true"
                                                      :filtering="false"
                                                      :options="select?.permanenttasks"
                                                      v-model:field="slotProps.record.premiumtask_id" />
                                    </td>

                                    <td v-else
                                        :class="slotProps.cellClass">
                                        <FormInputText v-if="slotProps.record.type == 'T'"
                                                       :style="{ width: '350px' }"
                                                       fieldName="target_name"
                                                       inputClass="sheet-fields"
                                                       :isFormDisabled="isFormDisabled || sheetFormButton == 'makeAssessment'"
                                                       v-model:field="slotProps.record.target_name" />

                                        <span v-else-if="slotProps.record.type == 'R'">
                                            <FormDropdown :style="{ width: '350px' }"
                                                          fieldName="rate_id"
                                                          optionClass="sheet-option-items"
                                                          :itemSize="20"
                                                          :isFormDisabled="isFormDisabled || sheetFormButton == 'makeAssessment'"
                                                          :inRepeater="true"
                                                          :filtering="false"
                                                          :options="select?.rates"
                                                          :change="() => getRateValue(slotProps.record, crud.current_year, crud.current_period)"
                                                          v-model:field="slotProps.record.rate_id" />
                                            <FormInputText fieldName="rate_conditions_80"
                                                           :label="__('Meet expectations')"
                                                           labelClass="sheet-labels"
                                                           inputClass="sheet-fields w-full"
                                                           style="margin:0;"
                                                           labelStyle="margin:0"
                                                           :isFormDisabled="isFormDisabled || sheetFormButton == 'makeAssessment'"
                                                           v-model:field="slotProps.record.rate_conditions_80" />
                                            <FormInputText fieldName="rate_conditions_100"
                                                           :label="__('Need improvement')"
                                                           labelClass="sheet-labels"
                                                           inputClass="sheet-fields w-full"
                                                           style="margin:0;padding-top:0"
                                                           labelStyle="margin:0"
                                                           :isFormDisabled="isFormDisabled || sheetFormButton == 'makeAssessment'"
                                                           v-model:field="slotProps.record.rate_conditions_100" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>

                                    <td :class="slotProps.cellClass">
                                        <span v-if="slotProps.record.type !== 'N'">
                                            <FormDropdown :style="{ width: '90px' }"
                                                          fieldName="weight"
                                                          optionClass="sheet-option-items"
                                                          :itemSize="20"
                                                          :isFormDisabled="isFormDisabled || isFormDisabled || sheetFormButton == 'makeAssessment'"
                                                          :inRepeater="true"
                                                          :filtering="false"
                                                          :options="select?.weights"
                                                          :change="(e) => calcAssessmentResult(e, slotProps.record, 'target')"
                                                          v-model:field="slotProps.record.weight" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>

                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <span v-if="slotProps.record.type !== 'N'">
                                            <FormDropdown :style="{ width: '200px' }"
                                                          optionClass="sheet-option-items"
                                                          :itemSize="20"
                                                          fieldName="assessment"
                                                          :isFormDisabled="isFormDisabled || sheetFormButton != 'makeAssessment'"
                                                          :inRepeater="true"
                                                          :filtering="false"
                                                          :options="select?.assessments"
                                                          :change="(e) => calcAssessmentResult(e, slotProps.record, 'target')"
                                                          v-model:field="slotProps.record.assessment" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <span v-if="slotProps.record.type !== 'N'">
                                            <FormInputNumber :style="{ width: '80px' }"
                                                             fieldName="targetResults"
                                                             suffix="%"
                                                             inputClass="sheet-fields"
                                                             :isFormDisabled="true"
                                                             :inRepeater="true"
                                                             v-model:field="targetResults[slotProps.record.id]" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <span v-if="slotProps.record.type !== 'N'">
                                            <FormTextarea :style="[{ width: '400px' }, sheetFormButton == 'makeAssessment' && slotProps.record.assessment == 0 ? requiredStyle : {}]"
                                                          fieldName="justification"
                                                          inputClass="sheet-fields"
                                                          :rows="2"
                                                          :cols="30"
                                                          :isFormDisabled="isFormDisabled || sheetFormButton != 'makeAssessment'"
                                                          :inRepeater="true"
                                                          v-model:field="slotProps.record.justification" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass">
                                        <span v-if="slotProps.record.type === 'R'">
                                            <FormInputText :style="{ width: '300px' }"
                                                           fieldName="ratevalue_id"
                                                           inputClass="sheet-fields"
                                                           :isFormDisabled="true"
                                                           v-model:field="rateValue[slotProps.record.rate_id]" />
                                        </span>
                                        <div v-else
                                             class="w-full"></div>
                                    </td>

                                </template>
                                <template #footer="slotProps">
                                    <td :class="slotProps.cellClass"></td>
                                    <td :class="slotProps.cellClass"></td>
                                    <td :class="slotProps.cellClass"
                                        class="sheet-sum">{{ targetsWeigthSum }}%</td>

                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"></td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"
                                        class="sheet-sum">{{ targetsResultSum }}%</td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"></td>
                                    <td v-if="fromAssessmentStage"
                                        :class="slotProps.cellClass"></td>

                                </template>

                            </FormRepeaterTable>
                        </div>
                        <!-- Koniec tabeli: Cele premiowe -->
                    </div>
                    <div class="grid">
                        <div class="sheet-section-title col-5">
                            {{ __('Sum of weights') }}
                        </div>
                        <div class='col-2'></div>
                        <div class="sheet-section-title col-5">
                            <span v-if="fromAssessmentStage">{{ __('Assessment') }}</span>
                        </div>

                        <div class="sheet-box-divider sheet-total-result col-5">
                            {{ totalWeightsResult }}%
                            <span v-if="totalWeightsResult != 100"
                                  class="sheet-total-result-error"
                                  style="margin-left:30px">{{ __('Sum of weights is not equal 100%. Correct partial weights!') }}</span>
                        </div>
                        <div class='col-2'></div>
                        <div :class="{ 'sheet-box-divider': fromAssessmentStage, 'sheet-total-result': true, 'col-5': true }">
                            <span v-if="fromAssessmentStage">{{ totalAssessmentResult }}%</span>
                            <span v-if="totalAssessmentResult > 100"
                                  class="sheet-total-result-error"
                                  style="margin-left:30px">{{ __('Total result exceeds 100%. Correct partial assessments!') }}</span>
                        </div>
                    </div>

                    <div v-if="crud?.is_passed_to_sap">
                        <span class="sheet-section-title">{{ __('Sheet was passed to SAP Premium System') }}</span>
                    </div>

                </TabPanel>

                <!-- <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel> -->
                <TabPanel v-if="crud.sheetlog"
                          :header="__('Changelog')"
                          headerClass="form-tab-header">
                    <SheetLog :log="crud?.sheetlog" />
                </TabPanel>

            </TabView>

            <div class="grid">
                <FormButtons :formProps="props.formProps"
                             :sourceForm="crud"
                             :beforeSave="beforeSave"
                             :validation="validation"
                             :buttonsVisibility="buttonsVisibility"
                             v-model:isFormDisabled="isFormDisabled">
                    <template #after-in-add-edit="buttonProps">
                        <CustomButton name="saveAsReady"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => saveAsReady(buttonProps.saveData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Save as ready') }}
                        </CustomButton>

                        <CustomButton name="saveAssessment"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => saveAssessment(buttonProps.saveData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Save assessment') }}
                        </CustomButton>
                    </template>
                    <template #after-in-show="buttonProps">
                        <CustomButton name="makeAssessment"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => makeAssessment(buttonProps.editData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Assess') }}
                        </CustomButton>
                        <CustomButton name="modifyTargets"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => modifyTargets(buttonProps.editData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Modify targets') }}
                        </CustomButton>

                        <CustomButton name="nextSheet"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => nextSheet(buttonProps.editData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Next') }}
                        </CustomButton>
                        <CustomButton name="copySheet"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => copySheet(buttonProps.editData)"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Copy') }}
                        </CustomButton>

                        <CustomButton name="printSheet"
                                      :buttonProps="buttonProps"
                                      :buttonsVisibility="buttonsVisibility"
                                      :click="() => printSheet()"
                                      style="margin:0 5px"
                                      severity="info">
                            {{ __('Print') }}
                        </CustomButton>

                    </template>
                </FormButtons>
            </div>


            <Dialog modal
                    :visible="isCopySheetModalOpen"
                    v-model:visible="isCopySheetModalOpen"
                    :header="__('Copying sheet')"
                    :style="{ width: '60%' }"
                    @keydown.esc="isCopySheetModalOpen = false"
                    @keydown.esc.stop>
                <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Sheets will be copied according to given criteria') }}</p>

                <CopySheetForm :sheetId="sheetId"
                               :currentPeriod="crud.current_period"
                               :formProps="props.formProps"
                               v-model:isCopySheetModalOpen="isCopySheetModalOpen">
                </CopySheetForm>
            </Dialog>

        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { usePage } from '@inertiajs/vue3'
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { validation, initForm, prepareSelect } from "@pages/Helper/crud_utils.js";
import FormInputText from "@crud/FormInputText.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormButtons from "@crud/FormButtons.vue";
import FormCalendar from "@crud/FormCalendar.vue";
import FormRepeaterTable from "@crud/FormRepeaterTable.vue";
import FormInputNumber from "@crud/FormInputNumber.vue";
import FormTextarea from "@crud/FormTextarea.vue";
import CustomButton from "@crud/CustomButton.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import CopySheetForm from "./CopySheetForm.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ChangeLog from "@crud/ChangeLog.vue";
import SheetLog from "./SheetLog.vue";
import { __, isNumeric, isNullish, screenMessage, getFromRoute, getStatusId, getField, printReport } from "@pages/Helper/utils.js";

// --------------------------------------------------------------------------------------------------------------------------------------------

const props = defineProps({
    formProps: Object,
    fromSheetsForWorker: { type: Boolean, default: false },
    workerId: { type: Number, default: null },
    initialConditions: { type: Object, default: null },
});
const page = usePage();

const isLoading = ref(true);
const isFormDisabled = ref(false);

const options = ["workers", "positions", "depts", "hrevents", "statuses", "premiumcriterions", "weights", "assessments", "target_types", "permanenttasks", "rates"];
const select = ref(null);
const buttonsVisibility = ref(null);
const crud = ref({});
const premium = ref({});
const initialData = ref({});

const criterionResults = ref([]);
const targetResults = ref([]);
const criterionWeights = ref([]);
const targetWeights = ref([]);

const criterionsWeigthSum = ref(0);
const targetsWeigthSum = ref(0);
const criterionsResultSum = ref(0);
const targetsResultSum = ref(0);

const totalAssessmentResult = ref(0);
const totalWeightsResult = ref(0);
const sheetFormButton = ref(null);
const percentValueOfHREvent = ref(null)

const isCopySheetModalOpen = ref(false);
const sheetId = ref(null)
const requiredStyle = ref({ 'border': '1px solid red' })
const rateValue = ref({})
const fromAssessmentStage = ref(false)
const refreshDataInForm = ref(false)

// --------------------------------------------------------------------------------------------------------------------------------------------

watch(() => ({ criterionResults, targetResults, criterionWeights, targetWeights }), (newValue, oldValue) => {
    criterionsResultSum.value = Object.values(criterionResults.value).reduce((a, b) => parseInt(a) + parseInt(b), 0);
    targetsResultSum.value = Object.values(targetResults.value).reduce((a, b) => parseInt(a) + parseInt(b), 0);
    criterionsWeigthSum.value = Object.values(criterionWeights.value).reduce((a, b) => parseInt(a) + parseInt(b), 0);
    targetsWeigthSum.value = Object.values(targetWeights.value).reduce((a, b) => parseInt(a) + parseInt(b), 0);
    totalAssessmentResult.value = criterionsResultSum.value + targetsResultSum.value;
    totalWeightsResult.value = criterionsWeigthSum.value + targetsWeigthSum.value;
}, { deep: true }, { immediate: true });

// --------------------------------------------------------------------------------------------------------------------------------------------

watch(() => ({ refresh: refreshDataInForm.value, page: page }), async (newValue, oldValue) => {
    if (newValue.refresh && page?.props?.flash?.message) {
        await initForm(props, crud, isLoading, options, select, buttonsVisibility, null, null, null, null, crud.value.id);
        refreshDataInForm.value = false;
    }
}, { deep: true }, { immediate: true })

// --------------------------------------------------------------------------------------------------------------------------------------------

onMounted(async () => {
    if (props.formProps.addMode) {
        initialData.value = await getFromRoute('sheet-initial', { 'worker_id': props.workerId, initialConditions: props.initialConditions }, 'post')
    }

    await initForm(props, crud, isLoading, options, select, buttonsVisibility, null, null, initialData.value);

    //await nextTick();
    if (crud.value && crud.value.premiumGroupDefinition) {
        premium.value = crud.value.premiumGroupDefinition;
    } else {
        premium.value = null;
    }

    if (crud.value.status_symbol == 'SYM_IN_PREPARATION' || crud.value.status_symbol == 'SYM_READY' || props.formProps.addMode) {
        fromAssessmentStage.value = false
    } else {
        fromAssessmentStage.value = true
    }

    recalculateAssessmentResult();
    getAllRateValues()
    checkPremiumRight()
    getPercentValueOfHREvent(crud.value.event_id)
});

// --------------------------------------------------------------------------------------------------------------------------------------------

const calcAssessmentResult = (e, record, source) => {
    if (isNumeric(record.weight) && isNumeric(record.assessment)) {
        switch (source) {
            case 'criterion':
                criterionResults.value[record.id] = ((record.weight * record.assessment) / 100).toFixed(0);
                criterionWeights.value[record.id] = record.weight;
                break;
            case 'target':
                targetResults.value[record.id] = ((record.weight * record.assessment) / 100).toFixed(0);
                targetWeights.value[record.id] = record.weight;
                break;
        }
    }
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const recalculateAssessmentResult = () => {
    if (crud.value.sheetCriterions) {
        crud.value.sheetCriterions.forEach((record) => {
            calcAssessmentResult(null, record, 'criterion');
        });
    }

    if (crud.value.sheetTargets) {
        crud.value.sheetTargets.forEach((record) => {
            calcAssessmentResult(null, record, 'target');
        });
    }
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const changeTargetType = (e, record) => {
    if (record.type === 'T') {
        record.rate_id = null;
        record.rate_conditions_80 = null;
        record.rate_conditions_100 = null;
        record.rate_value = null;
    } else if (record.type === 'R') {
        record.target_name = null;
    } else {
        record.rate_id = null;
        record.rate_conditions_80 = null;
        record.rate_conditions_100 = null;
        record.target_name = null;
        record.justification = null;
        record.rate_value = null;
        record.weight = 0;
        record.assessment = 0;
    }
    recalculateAssessmentResult()
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const getRateValue = async (record, year, period) => {
    if (record?.rate_id) {
        const result = await getFromRoute('rate-value', { 'rate_id': record.rate_id, 'year': year, 'period': period }, 'get')
        rateValue.value[record.rate_id] = result.rate_value
    }
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const checkIsPremiumRight = (e) => {
    if (premium.value.is_premium_right && !crud.value.is_premium_right) {
        crud.value.sheetCriterions.forEach((record) => {
            record.assessment = null;
        })

        crud.value.sheetTargets.forEach((record) => {
            record.assessment = null;
        })

        recalculateAssessmentResult()
    } else {
        crud.value.no_premium_right_reason = null
    }
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const getAllRateValues = async () => {
    if (crud.value?.sheetTargets) {
        crud.value.sheetTargets.forEach(async (record) => {
            await getRateValue(record, crud.value.current_year, crud.value.current_period)
        })
    }
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const getPercentValueOfHREvent = async (selectedValue) => {
    if (selectedValue) {
        percentValueOfHREvent.value = await getField("events", "percent_value", selectedValue)
    } else {
        percentValueOfHREvent.value = null
    }

    if (percentValueOfHREvent.value === 0) {
        crud.value.sheetCriterions.forEach((record) => {
            record.assessment = null;
        })

        crud.value.sheetTargets.forEach((record) => {
            record.assessment = null;
        })

        recalculateAssessmentResult()
    }
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const resetTotals = () => {
    criterionResults.value = [];
    criterionWeights.value = [];
    targetResults.value = [];
    targetWeights.value = [];
    rateValue.value = [];
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const checkPremiumRight = () => {
    if (!crud.value.premiumGroupDefinition.is_premium_right) {
        crud.value.is_premium_right = true;
    }
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const beforeSave = async (sourceForm) => {
    // ustawienie id na null dla nowych rekordów. Początkowo id dla nowych rekordów jest ustawione w celu zbierania sumy wag i wyników, ale nie można zapisać nowego rekordu z id, dlatego musi być wcześniej ustawione na null
    if (props.formProps.addMode) {
        sourceForm.sheetCriterions.forEach((record) => {
            record.id = null;
        });

        sourceForm.sheetTargets.forEach((record) => {
            record.id = null;
        });
    }
    sourceForm.sheetFormButton = sheetFormButton.value ?? 'save';
    return sourceForm;
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const saveAsReady = async (saveData) => {
    if (canSaveAsReady()) {
        sheetFormButton.value = 'saveAsReady';
        await saveData(props.formProps.addMode ? false : true);
        refreshDataInForm.value = true
    }
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const makeAssessment = (editData) => {
    sheetFormButton.value = 'makeAssessment';
    fromAssessmentStage.value = true
    editData();
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const saveAssessment = async (saveData) => {
    if (canSaveAssessment()) {
        sheetFormButton.value = 'saveAssessment';
        await saveData(true);
        refreshDataInForm.value = true
    }
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const modifyTargets = (editData) => {
    sheetFormButton.value = 'modifyTargets';
    editData();
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const nextSheet = async (editData) => {
    sheetFormButton.value = 'nextSheet';
    isLoading.value = true;
    const nextSheetData = await getFromRoute('sheet-next', { 'sheet_id': crud.value.id }, 'post')
    if (nextSheetData?.message) {
        const message = { message: nextSheetData.message, title: __("Last assessment sheet"), type: "info", position: "center", timeout: 5 };
        screenMessage(message)
    } else {
        crud.value = nextSheetData
        select.value = await prepareSelect(options, null, crud)

        buttonsVisibility.value = crud.value.buttonsVisibility
        premium.value = crud.value.premiumGroupDefinition;

        resetTotals();
        recalculateAssessmentResult();
        getAllRateValues()
        checkPremiumRight();
        getPercentValueOfHREvent(crud.value.event_id)
    }
    isLoading.value = false;
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const copySheet = async (editData) => {
    sheetFormButton.value = 'copySheet';
    isCopySheetModalOpen.value = true;
    sheetId.value = crud.value.id;
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const printSheet = async () => {
    isLoading.value = true;
    const response = await getFromRoute('sheet-print', { 'sheet_id': crud.value.id }, 'post')
    printReport(response)
    isLoading.value = false;
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const quantityAndSumControl = () => {
    let message = null;
    const filledPremiumTargets = crud.value.sheetTargets.filter((record) => record.type !== 'N').length;
    const sumWeightsQualityCriterions = crud.value.sheetCriterions.reduce((a, b) => a + b.weight, 0);

    if (filledPremiumTargets < premium.value.count_targets_min && !message) {
        //Ilość celów premiowych jest mniejsza niż minimalna liczba celów premiowych dla grupy premiowej
        message = __("Count of premium targets is lower than minimum count of targets for premium group (:count)", { count: premium.value.count_targets_min });
    }
    if (filledPremiumTargets > premium.value.count_targets_max && !message) {
        //Ilość celów premiowych jest mniejsza niż minimalna liczba celów premiowych dla grupy premiowej
        message = __("Count of premium targets is higher than maximum count of targets for premium group (:count)", { count: premium.value.count_targets_max });
    }

    if (sumWeightsQualityCriterions < premium.value.sum_weights_min && !message) {
        //suma wag kryteriów jakościowych jest mniejsza niż minimalna suma wag dla grupy premiowej
        message = __("Sum weights of quality criterion (:currSum) is lower than minimum sum of weights (:minSum)", { currSum: sumWeightsQualityCriterions, minSum: premium.value.sum_weights_min });
    }

    if (totalWeightsResult.value != 100 && !message) {
        //Suma wag wszystkich kryteriów musi być równa 100
        message = "Sum of weights is not equal 100%. Correct partial weights!"
    }

    if (totalAssessmentResult.value > 100 && !message) {
        //Suma wszystkich ocen nie może być większa niż 100%
        message = "Total result exceeds 100%. Correct partial assessments!"
    }

    return message
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const saveAsReadyConditions = () => {
    let message = null;

    const sheetCriterionsWithountNamesOrWeights = crud.value.sheetCriterions.filter((criterion) => {
        return criterion.premiumcriterion_id === null || criterion.premiumcriterion_id !== null && criterion.weight === 0;
    })
    const sheetTargetsTasksWithountNamesOrWeights = crud.value.sheetTargets.filter((target) => {
        return target.type === 'T' && ((target.premiumtask_id === null && isNullish(target.target_name)) || target.weight === 0);
    })
    const sheetTargetsRatesWithountNamesOrWeights = crud.value.sheetTargets.filter((target) => {
        return target.type === 'R' && (target.rate_id === null || (target.rate_id !== null && (isNullish(target.rate_conditions_80) || isNullish(target.rate_conditions_100)) || target.weight === 0));
    })

    if (sheetCriterionsWithountNamesOrWeights.length > 0 && !message) {
        message = "All quality criterions must have names and weights should be greater than 0"
    }
    if (sheetTargetsTasksWithountNamesOrWeights.length > 0 && !message) {
        message = "All premium targets with type 'Task' must have names and weights should be greater than 0"
    }
    if (sheetTargetsRatesWithountNamesOrWeights.length > 0 && !message) {
        message = "All premium targets with type 'Rate' must have rates and weights should be greater than 0 and filled conditions fields"
    }

    return message
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const saveAssessmentConditions = () => {
    let message = null;

    const sheetCriterionsWithountAssessments = crud.value.sheetCriterions.filter((criterion) => {
        return criterion.premiumcriterion_id !== null && criterion.assessment === null;
    })
    const sheetCriterionsWithountJustification = crud.value.sheetCriterions.filter((criterion) => {
        return criterion.assessment == 0 && criterion.weight > 0 && isNullish(criterion.justification);
    })

    const sheetTargetsTasksWithountAssessments = crud.value.sheetTargets.filter((target) => {
        return target.type === 'T' && target.premiumtask_id !== null && target.assessment === null;
    })
    const sheetTargetsRatesWithountAssessments = crud.value.sheetTargets.filter((target) => {
        return target.type === 'R' && target.rate_id !== null && target.assessment === null;
    })
    const sheetTargetsWithountJustification = crud.value.sheetTargets.filter((target) => {
        return target.assessment == 0 && target.weight > 0 && isNullish(target.justification);
    })

    if (sheetCriterionsWithountAssessments.length > 0 && !message) {
        message = "All quality criterions must have assessments"
    }
    if (sheetTargetsTasksWithountAssessments.length > 0 && !message) {
        message = "All premium targets with type 'Task' must have assessments"
    }
    if (sheetTargetsRatesWithountAssessments.length > 0 && !message) {
        message = "All premium targets with type 'Rate' must have assessments"
    }

    if (sheetCriterionsWithountJustification.length > 0 && !message) {
        message = "All quality criterions with assessment Do not meet expectations must have justification"
    }
    if (sheetTargetsWithountJustification.length > 0 && !message) {
        message = "All premium goals with assessment Do not meet expectations must have justification"
    }

    return message
}

// --------------------------------------------------------------------------------------------------------------------------------------------

const canSaveAsReady = () => {
    let message = null;
    const messageConst = { title: "Correct data before saving", type: "error", position: "center", timeout: 5 };

    if (!crud.value.is_premium_right) {
        return true;
    }
    if (crud.value.event_id && percentValueOfHREvent.value === 0) {
        return true;
    }

    message = saveAsReadyConditions()

    if (!message) {
        message = quantityAndSumControl()
    }

    if (message) {
        screenMessage({ message, ...messageConst });
        return false;
    }

    return true;
};

// --------------------------------------------------------------------------------------------------------------------------------------------

const canSaveAssessment = () => {
    let message = null;
    const messageConst = { title: "Correct data before saving", type: "error", position: "center", timeout: 5 };

    if (!crud.value.is_premium_right) {
        return true;
    }
    if (crud.value.event_id && percentValueOfHREvent.value === 0) {
        return true;
    }

    message = saveAsReadyConditions()
    if (!message) {
        message = saveAssessmentConditions()
    }

    if (!message) {
        message = quantityAndSumControl()
    }

    if (message) {
        screenMessage({ message, ...messageConst });
        return false;
    }

    return true
}

// --------------------------------------------------------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
