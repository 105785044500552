<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName"
            @keyup.enter="autoSave = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormStaticText label="Event"
                                    size="3"
                                    labelClass="static-text-label"
                                    class="static-text-content"
                                    :content="crud.event" />

                    <FormStaticText label="Operation date"
                                    size="3"
                                    labelClass="static-text-label"
                                    class="static-text-content"
                                    :content="crud.created_at" />

                    <FormStaticText label="Area"
                                    size="3"
                                    labelClass="static-text-label"
                                    class="static-text-content"
                                    :content="crud.log_name" />

                    <FormStaticText label="User"
                                    size="3"
                                    labelClass="static-text-label"
                                    class="static-text-content"
                                    :content="crud.causer" />

                </div>
                <div class="grid">
                    <FormStaticText label="Changes"
                                    labelClass="static-text-label"
                                    class="static-text-content"
                                    :content="crud.properties" />
                </div>
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled"
                         v-model:autoSave="autoSave">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormStaticText from '@crud/FormStaticText.vue'
import FormButtons from '@crud/FormButtons.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const autoSave = ref(false)

const crud = ref({})
const buttonsVisibility = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, null, null, buttonsVisibility)
})

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
