<template>
    <div :class="[outerClass, colSize]"
         :style="outerStyle">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Calendar autocomplete="off"
                  hourFormat="24"
                  locale="pl"
                  :name="fieldName"
                  :validation="validation"
                  :class="['w-full', { 'required-field': hasRequiredAttr }, className]"
                  :style="style"
                  :showIcon="showIcon"
                  :showButtonBar="showButtonBar"
                  :showTime="showTime"
                  :showOnFocus="showOnFocus"
                  :dateFormat="dateFormat"
                  :manualInput="manualInput"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  :disabled="isFormDisabled"
                  @date-select="dateSelect"
                  @clear-click="clearClick"
                  v-focus="isFocus"
                  v-model="field" />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Calendar from 'primevue/calendar';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    showIcon: {
        type: Boolean,
        default: true
    },
    showButtonBar: {
        type: Boolean,
        default: true
    },
    showTime: {
        type: Boolean,
        default: false
    },
    showOnFocus: {
        type: Boolean,
        default: false
    },
    dateFormat: {
        type: String,
        default: 'yy-mm-dd'
    },
    manualInput: {
        type: Boolean,
        default: true
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
    outerStyle: {
        type: [Object, String],
        default: null
    },
    style: {
        type: [Object, String],
        default: null
    },
    className: {
        type: String,
        default: null
    },
    dateSelect: {
        type: Function,
        default: () => {
        }
    },
    clearClick: {
        type: Function,
        default: () => {
        }
    },
    onMounted: {
        type: Function,
        default: () => {
        }
    },
    formProps: {
        type: Object,
        default: null
    },
    minDate: {
        type: Date,
        default: null
    },
    maxDate: {
        type: Date,
        default: null
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

//-------------------------------------------------------------------------------------------------

onMounted(() => {
    if (props.onMounted) {
        props.onMounted(field.value)
    }
})

//-------------------------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
