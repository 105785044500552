<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName"
            @keyup.enter="autoSave = false">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="event"
                                   label="Event"
                                   size="10"
                                   validation="required"
                                   :isFormDisabled="isFormDisabledForNonAdmin"
                                   :isFocus="true"
                                   v-model:field="crud.event" />
                    <FormInputToggleButton fieldName="is_active"
                                           label="Is mailing active?"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_active" />

                </div>
                <div class="grid">
                    <FormInputText fieldName="description"
                                   label="Description"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.description" />
                </div>

                <div class="grid">
                    <FormInputText fieldName="subject"
                                   label="Subject"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.subject" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="body"
                                label="Body"
                                toolbar="large"
                                :editorStyle="{ height: '220px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.body" />
                </div>

            </TabPanel>

            <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>

        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled"
                         v-model:autoSave="autoSave">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, getStoreValue, is } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const autoSave = ref(false)
const isFormDisabledForNonAdmin = ref(false)
const buttonsVisibility = ref(null);

const crud = ref({})
const percentValues = [
    { label: '0%', value: 0 },
    { label: '100%', value: 100 },
]

// -----------------------------------------------------

onMounted(async () => {
    let superAdminRole = getStoreValue('superAdminRole')
    isFormDisabledForNonAdmin.value = !is(superAdminRole)

    await initForm(props, crud, isLoading, null, null, buttonsVisibility)
})

// -----------------------------------------------------


</script>

<style lang="scss"
       scoped></style>
